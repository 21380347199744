import { ADD_DATA_TO_DB, CONNECT_TO_DB, GET_DB_DATA, GET_OBJECT_STORE, SAVE_OBJECT_STORE } from "../../browser-db-config/indexedDB";
import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {
  allProducts: null,
  createdProdRes: null,
  barcode: null,
  productDeleteRes: null,
};

const getters = {
  GET_ALL_PRODUCT: (state) => state.allProducts,
  GET_CREATED_PRODUCT_RES: (state) => state.createdProdRes,
  GET_GENERATED_BARCODE: (state) => state.barcode,
  GET_PRODUCT_DELETE_RES: (state) => state.productDeleteRes,
};

const actions = {
  FETCH_ALL_PRODUCTS: async function (context) {
    // Fetch not synced products
    // Fetch synced offline products || {}

    // if (!offlineProducts) {
    //   // So Request url condition doesn't break
    //   offlineProducts = {};
    // }

    // Open and get offline products
    // const offlineProductsDB = await CONNECT_TO_DB('offline_db_products', 1, 'products');
    // let offlineProducts = await GET_DB_DATA(offlineProductsDB, 'products');
    let offlineProducts = await GET_OBJECT_STORE('offline_db_products', 'products');



    // So Request url condition doesn't break


    // Fetch products form server
    const httpReq = {
      url: `/v2/businesses/${GET_USER_BUSINESS_ID()}/products${(offlineProducts || {}).metaData
        ? "?updated_at=" + offlineProducts.metaData.lastUpdate
        : ""
        }`,
      method: "GET",
      authRequest: true,
    };

    // Fetch online
    HTTP_REQUEST(httpReq).then(httpRes => {
      // Sort out the local products data and the newly fetched ones
      const resProducts = (httpRes.data || {}).items || [];

      if (!offlineProducts) {
        offlineProducts = {};
      }

      // Edit the local data
      if (offlineProducts.items) {
        // let matchFound = false;

        offlineProducts.items.forEach((offlineItem, index) => {
          resProducts.forEach((fetchedItem) => {
            // Manage updates
            if (fetchedItem._id === offlineItem._id) {
              // matchFound = true;
              offlineProducts.items[index] = { ...fetchedItem };
            }
            // Add the rest after updates are done

            // else if (!matchFound && index + 1 === offlineProducts.items.length) {
            //   console.log(fetchedItem._id);
            //   offlineProducts.items.push({ ...fetchedItem, wc_sync_status: true });
            // }
          });
        });

        const allProductsIds = offlineProducts.items.map(product => product._id);

        // Add products that were newly added
        resProducts.forEach(resProduct => {
          if (!allProductsIds.includes(resProduct._id)) {
            offlineProducts.items.push({ ...resProduct, wc_sync_status: true });
          }
        });
        
      } else {
        offlineProducts.items = httpRes.data.items;
      }

      // Check for deleted products
      if (httpRes.data.metaData.recordsRemoved) {
        offlineProducts.items = offlineProducts.items.filter(prod => !httpRes.data.metaData.recordsRemoved.includes(prod._id));
      }

      offlineProducts.metaData = httpRes.data ? httpRes.data.metaData : offlineProducts.metaData;
      offlineProducts.message = httpRes.message ? httpRes.message : offlineProducts.message;
      offlineProducts.status = httpRes.status ? httpRes.status : offlineProducts.status;

      if (httpRes.data) {
        // Save assets to indexDB
        // await ADD_DATA_TO_DB(offlineProductsDB, 'products', offlineProducts);
        SAVE_OBJECT_STORE('offline_db_products', 'products', offlineProducts);
      }

      context.commit("SETUP_PRODUCTS", offlineProducts);
    });


    if (!offlineProducts) {
      offlineProducts = await HTTP_REQUEST(httpReq);
      if (offlineProducts.status) {
        offlineProducts = { ...offlineProducts.data, message: offlineProducts.message, status: offlineProducts.status };
      }
    }



    // Sort out the local products data and the newly fetched ones
    // const resProducts = httpRes.data ? httpRes.data.items ? httpRes.data.items : [] : [];

    // Edit the local data
    // if (offlineProducts.items) {
    //   let matchNotFoundFlag = true;

    //   offlineProducts.items.forEach((offlineItem, index) => {
    //     resProducts.forEach((fetchedItem) => {
    //       if (fetchedItem._id === offlineItem._id) {
    //         matchNotFoundFlag = false;
    //         offlineProducts.items[index] = { ...fetchedItem };
    //       } else if (matchNotFoundFlag && index + 1 === offlineProducts.items.length) {
    //         offlineProducts.items.push({ ...fetchedItem, wc_sync_status: true });
    //       }
    //     });
    //   })
    // } else {
    //   // offlineProducts.items = httpRes.data.items;
    // }

    // Check for deleted products
    // if (httpRes.data.metaData.recordsRemoved) {
    //   offlineProducts.items = offlineProducts.items.filter(prod => !httpRes.data.metaData.recordsRemoved.includes(prod._id));
    // }

    // offlineProducts.metaData = httpRes.data ? httpRes.data.metaData : offlineProducts.metaData;
    // offlineProducts.status = httpRes.status ? httpRes.status : offlineProducts.status;

    // if (httpRes.status) {
    //   // Save assets to indexDB
    //   // await ADD_DATA_TO_DB(offlineProductsDB, 'products', offlineProducts);
    //   await SAVE_OBJECT_STORE('offline_db_products', 'products', offlineProducts);

    // }


    context.commit("SETUP_PRODUCTS", offlineProducts);

    return offlineProducts;
  },
  CREATE_PRODUCT: async function (context, payload) {
    // Open and get offline products
    // const offlineProductsDB = await CONNECT_TO_DB('offline_db_products', 1, 'products');
    let offlineProducts = await GET_OBJECT_STORE('offline_db_products', 'products');

    // Feel this should be done on the Products.vue
    payload.updated_at = new Date().toISOString();

    const httpReq = {
      url: `/v1/businesses/${GET_USER_BUSINESS_ID()}/products`,
      method: "POST",
      authRequest: true,
      body: payload,
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    if (httpRes.status) {
      if (!offlineProducts) {
        offlineProducts = {
          items: []
        };
      }

      if (!offlineProducts.items) {
        offlineProducts.items = [];
      }

      offlineProducts.items.push(httpRes.data.product);

      // Save assets to indexDB
      await SAVE_OBJECT_STORE('offline_db_products', 'products', offlineProducts);
    }

    context.commit("SETUP_CREATED_PRODUCT", offlineProducts);
  },
  UPLOAD_BULK_PRODUCTS: async function (context, payload) {
    const httpReq = {
      url: `/v1/businesses/${GET_USER_BUSINESS_ID()}/uploadproducts`,
      method: "POST",
      authRequest: true,
      body: payload,
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    return httpRes;
  },
  RE_UPLOAD_FAILED_PRODUCT: async function (context, payload) {
    const httpReq = {
      url: `/v1/businesses/${GET_USER_BUSINESS_ID()}/reprocess/product`,
      method: "POST",
      authRequest: true,
      body: payload,
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    return httpRes;
  },
  FETCH_ALL_BATCHES: async function (context, payload) {
    const httpReq = {
      url: `/v1/businesses/${GET_USER_BUSINESS_ID()}/uploadproducts/batches`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    return httpRes;
  },
  FETCH_FAILED_BULK_UPLOAD_PRODUCTS: async function (context, payload) {
    const httpReq = {
      url: `/v1/businesses/${GET_USER_BUSINESS_ID()}/${payload.batchNumber}/upload-batch/products`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    return httpRes;
  },
  UPDATE_PRODUCT: async function (context, payload) {
    const productId = payload._id;

    // Open and get offline products
    // const offlineProductsDB = await CONNECT_TO_DB('offline_db_products', 1, 'products');
    let offlineProducts = await GET_OBJECT_STORE('offline_db_products', 'products');

    // Safely remove _id
    const payloadToUpdate = Object.keys(payload).reduce((object, key) => {
      if (key !== "_id") {
        object[key] = payload[key];
      }
      return object;
    }, {});
    // Feel this should be done on the Products.vue
    payload.updated_at = new Date().toISOString();

    const httpReq = {
      url: `/v1/businesses/${GET_USER_BUSINESS_ID()}/products/${productId}`,
      method: "PATCH",
      authRequest: true,
      body: payloadToUpdate,
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    if (httpRes.status) {
      if (!offlineProducts) {
        offlineProducts = {
          items: []
        };
      }

      offlineProducts.items = offlineProducts.items.map(item => {
        if (item._id === productId) {
          item = httpRes.data;
        }
        return item;
      });

      // Save assets to indexDB
      await SAVE_OBJECT_STORE('offline_db_products', 'products', offlineProducts);
    }

    context.commit("SETUP_CREATED_PRODUCT", offlineProducts);
  },
  DELETE_PRODUCT: async function (context, payload) {
    // Open and get offline products
    // const offlineProductsDB = await CONNECT_TO_DB('offline_db_products', 1, 'products');
    let offlineProducts = await GET_OBJECT_STORE('offline_db_products', 'products');

    const httpReq = {
      url: `/v1/businesses/${GET_USER_BUSINESS_ID()}/products/${payload._id}`,
      method: "DELETE",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    if (httpRes.status) {
      offlineProducts.items = offlineProducts.items.filter(item => item._id !== payload._id);

      // Save assets to indexDB
      await SAVE_OBJECT_STORE('offline_db_products', 'products', offlineProducts);
    }

    context.commit("SETUP_PRODUCT_DELETE_RES", httpRes);
  },
  GENERATE_BARCODE: async function (context) {
    const httpReq = {
      url: `/v1/businesses/${GET_USER_BUSINESS_ID()}/products/barcode/`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    context.commit("POPULATE_GENERATED_BARCODE", httpRes);
  },
};

const mutations = {
  SETUP_PRODUCTS: (state, data) => {
    state.allProducts = data;
  },
  SETUP_PRODUCT_DELETE_RES: (state, data) => {
    state.productDeleteRes = data;
  },
  POPULATE_GENERATED_BARCODE: (state, data) => {
    state.barcode = data;
  },
  SETUP_CREATED_PRODUCT: (state, data) => {
    state.createdProdRes = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
