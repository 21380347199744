import { GET_USER_BUSINESS_ID } from "../../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../../http-config/trac-http-req";

const state = {
  allStores: null,
  resMassage: null,
};

const getters = {
  GET_RESPONSE_MESSAGE: () => state.resMassage,
};

const actions = {
  TOGGLE_PAYMENT_OPTIONS: async function(context, payload) {
    const httpReq = {
      url: `/v1/payments/options/businesses/${GET_USER_BUSINESS_ID()}`,
      method: "PATCH",
      authRequest: true,
      body: payload,
    };

    const httpRes = await HTTP_REQUEST(
      httpReq
    );

    context.commit("RESPONSE_MESSAGE", httpRes);
  },
};

const mutations = {
  SETUP_STORES: (state, data) => {
    state.allStores = data;
  },
  RESPONSE_MESSAGE: (state, payload) => {
    state.resMassage = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
