import { GET_USER_BUSINESS_ID } from "../../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../../http-config/trac-http-req";

const state = {
  allStores: null,
  resMassage: null,
};

const getters = {
  GET_COMPANY_INFO_RESPONSE: () => state.resMassage,
};

const actions = {
  UPDATE_USER_PROFILE: async function(context, payload) {
    const httpReq = {
      url: `/v1/businesses/${GET_USER_BUSINESS_ID()}`,
      method: "PATCH",
      authRequest: true,
      body: payload,
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    context.commit("COMPANY_INFO_RESPONSE", httpRes);
  },
};

const mutations = {
  SETUP_STORES: (state, data) => {
    state.allStores = data;
  },
  COMPANY_INFO_RESPONSE: (state, payload) => {
    state.resMassage = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
