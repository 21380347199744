<template>
  <main id="table">
    <div class="table-shadow p-6 rounded-md">
      <div class="flex w-full items-center">
        <div class="relative max-w-sm rounded-md w-full flex items-center">
          <input
            type="text"
            placeholder="Search customer name or amount paid"
            class="input-shadow py-2 w-full pl-5 text-xs focus:outline-none leading-7 tracking-wide text-primaryGray"
          />
          <svg
            class="w-4 h-4 absolute right-0 mr-6"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="6.56705"
              cy="6.61686"
              r="5.39909"
              stroke="#253B95"
              stroke-width="1.06786"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M13.3156 13.3659L10.3799 10.4302"
              stroke="#253B95"
              stroke-width="1.06786"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="ml-8 relative w-full">
          <button
            v-click-outside="close"
            @click="active = !active"
            id="options-menu"
            aria-haspopup="true"
            aria-expanded="true"
            type="button"
            class="border smallest text-primaryBlue focus:outline-none border-primaryBlue py-2 px-4 rounded inline-flex w-32 justify-between capitalize items-center"
          >
            Add filter
            <svg
              class="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </button>
          <div
            v-show="active"
            @click.stop
            class="absolute white-gradient px-4 py-6 max-w-sm w-full bg-white mt-1 rounded-md"
          >
            <div class="flex w-full justify-between">
              <div class="w-full ml-8">
                <p class="text-xs font-medium">By Date</p>
                <div class="mt-2 flex items-center">
                  <input type="radio" name="" id="" />
                  <p class="text-xs ml-3 leading-7">Today</p>
                </div>
                <div class="mt-2 flex items-center">
                  <input type="radio" name="" id="" />
                  <p class="text-xs ml-3 leading-7">This Month</p>
                </div>
                <div class="mt-2 flex items-center">
                  <input type="radio" name="" id="" />
                  <p class="text-xs ml-3 leading-7">Last Month</p>
                </div>
                <div
                  @click="open = true"
                  class="mt-2 flex relative items-center cursor-pointer"
                >
                  <svg
                    class="w-3 h-3"
                    viewBox="0 0 15 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.975586"
                      y="2.43408"
                      width="12.7281"
                      height="12.2995"
                      rx="1.65333"
                      stroke="#253B95"
                      stroke-width="1.23999"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.168 1.06738V3.8006"
                      stroke="#253B95"
                      stroke-width="1.23999"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4.51079 1.06738V3.8006"
                      stroke="#253B95"
                      stroke-width="1.23999"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M0.975586 6.53404H13.7037"
                      stroke="#253B95"
                      stroke-width="1.23999"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <p
                    class="text-xs ml-3 leading-7 text-primaryBlue cursor-pointer"
                  >
                    Select Date
                  </p>
                  <div class="relative">
                    <trac-date-picker
                      class="hidden absolute"
                      ref="calender"
                      :open.sync="open"
                      format="YYYY-MM-DD"
                      type="date"
                    ></trac-date-picker>
                  </div>
                </div>
              </div>
              <div class="border-l pl-8 w-full">
                <p class="text-xs font-medium">By Status</p>
                <div class="mt-2 flex items-center">
                  <input type="radio" name="" id="" />
                  <p class="text-xs ml-3 leading-7">Pending</p>
                </div>
                <div class="mt-2 flex items-center">
                  <input type="radio" name="" id="" />
                  <p class="text-xs ml-3 leading-7">Paid</p>
                </div>
                <div class="mt-2 flex items-center">
                  <input type="radio" name="" id="" />
                  <p class="text-xs ml-3 leading-7">Failed</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="w-full mt-8">
          <div>
            <div
              :style="`grid-template-columns:${gridColumns}`"
              class="grid rounded-tl-md rounded-tr-md border"
            >
              <div
                v-for="(header, index) in getTableHeader"
                :key="index"
                :class="[
                  index === 0 || index === getTableHeader.length - 1
                    ? 'rounded-tl-md rounded-tr-md'
                    : '',
                  { 'grid-space+': index === 0 },
                ]"
                class="pl-8 py-6 bg-accentLight uppercase text-xs font-medium w-full"
              >
                {{ header }}
              </div>
            </div>
          </div>
          <div>
            <div
              :style="`grid-template-columns:${gridColumns}`"
              :class="[
                i == contents.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
              ]"
              class="grid last:border-b border-l border-r even:bg-veryLight items-center"
              v-for="(content, i) in contents"
              :key="i"
            >
              <div
                class="w-full pl-8 text-xs text-accentDark font-normal flex-col"
                v-for="(s, u) in items.length"
                :key="u"
                :class="[`table-content${u}`, showAvatar ? 'py-6' : 'py-8']"
              >
                <div
                  :class="{
                    ' flex justify-between items-center': u == items.length - 1,
                  }"
                >
                  <div class="flex items-center">
                    <div
                      v-if="u == 0 && showAvatar"
                      class="w-10 h-10 rounded-full border justify-center mr-5 flex items-center"
                    >
                      {{ initials[i] }}
                    </div>
                    <div :class="status(content[items[u]])">
                      {{ content[items[u]] }}
                    </div>
                  </div>
                  <slot v-if="u == items.length - 1" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ClickOutside from "vue-click-outside";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
  props: {
    contents: Array,
    showAvatar: {
      default: false,
      type: Boolean,
    },
  },

  directives: {
    ClickOutside,
  },

  components: { DatePicker },

  data() {
    return {
      active: false,
      open: false,
      items: [],
    };
  },
  methods: {
    close() {
      this.active = false;
    },

    popModal(x) {
      this.modal = true;
    },

    repeat(str, num) {
      var holder = [];
      for (var i = 0; i < num; i++) {
        holder.push(str);
      }
      return holder.join(" ");
    },

    openDate() {
      this.$refs.calender.$el.click();
    },

    status(x) {
      if (x === "Paid Out") {
        return "text-green-500 cap font-medium";
      } else if (x === "Pending") {
        return "text-yellow-500 cap font-medium";
      }
    },

    titleCase(string) {
      let sentence = string.toLowerCase().split(" ");
      for (let i = 0; i < sentence.length; i++) {
        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
      }
      return sentence.join(" ");
    },

    getInitials(string) {
      let names = string.split(" "),
        initials = names[0].substring(0, 1).toUpperCase();

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }
      return initials;
    },
  },
  computed: {
    gridColumns() {
      let k = "1fr";
      let b = this.repeat(k, this.items.length - 1);
      return `1.4fr ${b}`;
    },

    getTableHeader() {
      let k = [...this.contents];
      let b = Object.keys(k[0]).map((k) => k);
      let u = Object.keys(k[0]).map((k) => {
        return this.titleCase(k).replace(/_/g, " ");
      });
      this.items = b;
      return u;
    },

    initials() {
      let k = [...this.contents];

      let b = k.map((u) => {
        return this.getInitials(u.name);
      });
      return b;
    },
  },
};
</script>

<style>
.table-shadow {
  box-shadow: 0px 0px 20px rgba(22, 120, 176, 0.12);
}
.input-shadow {
  box-shadow: 0px 0px 21.3571px rgba(22, 120, 176, 0.12);
}

.table-even {
  background: #f9f9f9;
}
.table-content0 {
  font-weight: 600 !important;
}
.cap {
  text-transform: capitalize !important;
}
.grid-space {
  grid-template-columns: 1.5fr !important;
}
</style>
