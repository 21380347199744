<template>
  <trac-center-data>
    <div
      class="z-30 fixed top-0 left-0 bg-transparentBackground flex justify-center items-center w-screen h-screen"
    >
      <!-- class=" fixed loading-overlay flex justify-center items-center w-screen h-screen" -->
      <ul class="loading inline-flex items-center w-32 mb-20 justify-between">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  </trac-center-data>
</template>

<script>
export default {
  mounted() {
    // document.body.style.setProperty("overflow", "hidden");
    // document.body.style.setProperty("filter", "blur(1.2px)")
  },
  destroyed() {
    document.body.style.removeProperty("overflow");
  },
};
</script>

<style>
.loading-overlay {
  /* width: 100vw;
  height: 100vh; */
  /* top: 0;
  left: 0; */
}

.loading li {
  height: 5px;
  width: 5px;
  border: 2px solid #253b95a1;
  border-radius: 100%;
  -webkit-transform: transformZ(0);
  transform: transformZ(0);
  -webkit-animation: LOADING 2s infinite;
  animation: LOADING 2s infinite;
}
.loading li:nth-child(1n) {
  left: -20px;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.loading li:nth-child(2n) {
  left: 0;
  height: 10px;
  width: 10px;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.loading li:nth-child(3n) {
  height: 15px;
  width: 15px;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.loading li:nth-child(4n) {
  height: 20px;
  width: 20px;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.loading li:nth-child(5n) {
  height: 25px;
  width: 25px;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

@keyframes LOADING {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    background: #253b956d;
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
    background: white;
  }
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    background: #253b956d;
  }
}
</style>
