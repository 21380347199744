import { ADD_DATA_TO_DB, CONNECT_TO_DB, GET_DB_DATA, GET_OBJECT_STORE, SAVE_CURRENT_BUSINESS_TO_OBJECT_STORE, SAVE_OBJECT_STORE } from "../../browser-db-config/indexedDB";
import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";
import { eventBus } from "./../../main";
import router from './../../router';


const state = {
  allCustomers: null,
  customersStatsOverTime: null,
  customerCreateRes: null,
  customerActionRes: null,
  customerLoyaltyRes: null,
  currentCustomerSalesRecords: null,
  currentCustomerInvoicesRecords: null
};

const getters = {
  GET_ALL_CUSTOMERS: (state) => state.allCustomers,
  GET_CUSTOMER_ACTION_RES: (state) => state.customerCreateRes,
  GET_CUSTOMER_LOYALTY_RES: (state) => state.customerLoyaltyRes,
  GET_CUSTOMERS_STATS: (state) => state.customersStatsOverTime,
  GET_CUSTOMER_SALES_RECORDS: (state) => state.currentCustomerSalesRecords,
  GET_CUSTOMER_INVOICES_RECORDS: (state) => state.currentCustomerInvoicesRecords,
};

const offlineIDs = [];

const makeid = (l) => {
  let text = '';
  let charList = 'abcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < l; i++) {
    text += charList.charAt(Math.floor(Math.random() * charList.length));
  }

  if (offlineIDs.includes(text)) {
    text = '';
    return makeid(l);
  } else {
    offlineIDs.push(text);
    return text;
  }
};

const actions = {
  FETCH_ALL_CUSTOMERS: async function (context) {
    // fetch from db
    // fetch from db (not-synced)
    // const notSyncedOfflineCustomersDB = await CONNECT_TO_DB('not_synced_offline_customers', 1, 'not_synced_customers');
    // let notSyncedOfflineCustomers = await GET_DB_DATA(notSyncedOfflineCustomersDB, 'not_synced_customers');
    let notSyncedOfflineCustomers = await GET_OBJECT_STORE('not_synced_offline_customers', 'not_synced_customers');


    if (!notSyncedOfflineCustomers) {
      notSyncedOfflineCustomers = [];
    }

    // Open and get offline customers
    // const offlineCustomersDB = await CONNECT_TO_DB('offline_db_customers', 1, 'customers');
    // let offlineCustomers = await GET_DB_DATA(offlineCustomersDB, 'customers');

    let offlineCustomers = await GET_OBJECT_STORE('offline_db_customers', 'customers');


    // Setup request
    const httpReq = {
      url: `/v2/customers/businesses/${GET_USER_BUSINESS_ID()}${((offlineCustomers || {}).data || {}).metaData
        ? "?updated_at=" + offlineCustomers.data.metaData.lastUpdate
        : ""
        }`,
      method: "GET",
      authRequest: true,
    };

    // Make request
    HTTP_REQUEST(httpReq).then(httpRes => {
      if (!offlineCustomers) {
        offlineCustomers = {
          data: {}
        };
      }
      if (httpRes.status) {
        if (httpRes.data) {
          if (httpRes.data.items) {
            offlineCustomers.data.items = offlineCustomers.data.items || [];
            if (((httpRes.data || {}).items || []).length > 0 && offlineCustomers.data.items.length > 0 && router.currentRoute.fullPath === '/customers') {
              eventBus.$emit("trac-alert", { message: 'New customer(s) have been added please refresh your page.' });
            }
            offlineCustomers.data.items.push(...httpRes.data.items);
          } else {
            offlineCustomers.data.items = offlineCustomers.data.items || [];
          }
        }

        offlineCustomers.data.metaData = httpRes.data.items ? httpRes.data.metaData : offlineCustomers.data.metaData;
        offlineCustomers.status = httpRes.status ? httpRes.status : offlineCustomers.status;

        // await ADD_DATA_TO_DB(offlineCustomersDB, 'customers', offlineCustomers);
        // Save assets to indexDB
        SAVE_OBJECT_STORE('offline_db_customers', 'customers', offlineCustomers);
        context.commit('SET_UP_CUSTOMERS', offlineCustomers);
      }

      if (!offlineCustomers.data) {
        offlineCustomers = {
          data: []
        }
      }

      offlineCustomers.data.items = [...offlineCustomers.data.items, ...notSyncedOfflineCustomers];

      context.commit('SET_UP_CUSTOMERS', offlineCustomers);
    });

    if (!offlineCustomers) {
      offlineCustomers = await HTTP_REQUEST(httpReq);
    }

    context.commit('SET_UP_CUSTOMERS', offlineCustomers);
  },
  FETCH_CUSTOMERS_STATS_OVER_TIME_PERIOD: async function (context, payload) {
    const httpReq = {
      url: `/v1/dashboard/customer/${GET_USER_BUSINESS_ID()}/${payload.startDate
        }/${payload.endDate}/${payload.storeID}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_UP_CUSTOMERS_STATS_OVER_TIME", httpRes);
  },
  CREATE_OFFLINE_CUSTOMER: async (context, payload) => {
    // fetch from db
    // fetch from db (not-synced)

    // Open and get offline customers
    // const notSyncedOfflineCustomersDB = await CONNECT_TO_DB('not_synced_offline_customers', 1, 'not_synced_customers');
    // let notSyncedOfflineCustomers = await GET_DB_DATA(notSyncedOfflineCustomersDB, 'not_synced_customers');

    let notSyncedOfflineCustomers = await GET_OBJECT_STORE('not_synced_offline_customers', 'not_synced_customers');

    if (!notSyncedOfflineCustomers) {
      notSyncedOfflineCustomers = [];
    }

    notSyncedOfflineCustomers.push({ ...payload, business: GET_USER_BUSINESS_ID(), is_offline_data: true });

    // Save assets to indexDB
    // await ADD_DATA_TO_DB(notSyncedOfflineCustomersDB, 'not_synced_customers', notSyncedOfflineCustomers);
    await SAVE_OBJECT_STORE('not_synced_offline_customers', 'not_synced_customers', notSyncedOfflineCustomers);

    // const payloadToSync = (notSyncedOfflineCustomers || []).map(customer => {
    //   delete customer.created_at;
    //   return { ...customer, company_name: '', offline_cust_id: makeid(24), business: GET_USER_BUSINESS_ID() };
    // });

    // let offlineCustomers = await GET_OBJECT_STORE('offline_db_customers', 'customers');

    // const httpReq = {
    //   url: `/v1/customers/syncnewofflinecustomers`,
    //   method: "POST",
    //   authRequest: true,
    //   body: payloadToSync,
    // };

    // const httpRes = await HTTP_REQUEST(httpReq);

    // if (httpRes.status) {
    //   console.log(httpRes);

    //   const DBIDs = (httpRes.data || []).map((customer) => customer.offline_cust_id);

    //   const notSyncedCustomers = (payloadToSync || []).filter(d => {
    //     return !DBIDs.includes(d.offline_cust_id);
    //   });

    //   await SAVE_OBJECT_STORE('not_synced_offline_customers', 'not_synced_customers', notSyncedCustomers);


    //   // if (!(offlineCustomers || {}).data) {
    //   //   offlineCustomers = {
    //   //     data: [],
    //   //   };
    //   // }

    //   // offlineCustomers.data.items = [...offlineCustomers.data.items, ...(httpRes.data || [])];
    //   // await SAVE_OBJECT_STORE('offline_db_customers', 'customers', offlineCustomers);
    //   context.dispatch("REFRESH_ALL_CUSTOMERS");
    // } else {
    //   // offlineCustomers.data.items = [...offlineCustomers.data.items, { ...payload, business: GET_USER_BUSINESS_ID(), is_offline_data: true } ];
    // }

    // SAVE_CURRENT_BUSINESS_TO_OBJECT_STORE().then(data => {
    //   console.log(data);
    //   // Register Sync event in SW

    // }).catch(err => console.log(err));

    navigator.serviceWorker.ready.then(swReg => {
      swReg.sync.register('not_synced_offline_customers');
    });

    context.commit('SETUP_CUSTOMER_CREATE_RES', { status: true, data: { ...payload, business: GET_USER_BUSINESS_ID(), is_offline_data: true } });
  },
  CREATE_CUSTOMER: async function (context, payload) {
    const httpReq = {
      url: `/v1/customers/`,
      method: "POST",
      authRequest: true,
      body: { ...payload, business: GET_USER_BUSINESS_ID() },
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    context.commit('SETUP_CUSTOMER_CREATE_RES', httpRes);

    // Open and get offline customers
    // const offlineCustomersDB = await CONNECT_TO_DB('offline_db_customers', 1, 'customers');
    let offlineCustomers = await GET_OBJECT_STORE('offline_db_customers', 'customers');

    // if error occured
    if (httpRes.status) {
      offlineCustomers.data.items = [...offlineCustomers.data.items, httpRes.data.customer];
      // Save assets to indexDB
      await SAVE_OBJECT_STORE('offline_db_customers', 'customers', offlineCustomers);
    }
  },
  UPDATE_CUSTOMER: async function (context, payload) {
    // try {

    // } catch (error) {
    //   eventBus.$emit('trac-error-alert', {})
    // }
    const httpReq = {
      url: `/v1/customers/${payload._id}`,
      method: "PATCH",
      authRequest: true,
      body: {
        first_name: payload.first_name,
        last_name: payload.last_name,
        email: payload.email,
        phone: payload.phone,
        day_month: payload.day_month,
        address: {
          street: payload.address.street,
          city: payload.address.city,
          state: payload.address.state,
          country: payload.address.country,
        },
      },
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    if (httpRes.status) {
      // Save as offline
      // const offlineCustomers = await CONNECT_TO_DB('offline_db_customers', 1, 'customers');
      let offlineCustomers = await GET_OBJECT_STORE('offline_db_customers', 'customers');

      offlineCustomers.data.items.forEach((customer, i) => {
        //  ? httpRes.data.customer : customer
        if (customer._id === httpRes.data.customer._id) {
          offlineCustomers.data.items[i] = httpRes.data.customer;
        }
      });

      // Save assets to indexDB
      await SAVE_OBJECT_STORE('offline_db_customers', 'customers', offlineCustomers);
    }
    context.commit('SETUP_CUSTOMER_CREATE_RES', httpRes);
  },
  FETCH_CURRENT_CUSTOMER_SALES_RECORDS: async function (context, payload) {

    const customerSalseHttpRequestData = {
      // url: `/v1/sales/businesses/${GET_USER_BUSINESS_ID()}?${payload.email
      //   ? `customer_email=${payload.email}`
      //   : `customer_name=${payload.first_name +
      //   ' ' +
      //   payload.last_name}`
      //   }`,
      url: `/v1/sales/salesbycustomer/${GET_USER_BUSINESS_ID()}?customerid=${payload._id}`,
      method: 'GET',
      authRequest: true
    };

    const customerSalesRequestResponse = await HTTP_REQUEST(
      customerSalseHttpRequestData
    );

    context.commit('SET_CUSTOMER_SALES_RECORD', customerSalesRequestResponse.data || []);
    // context.commit('SET_CUSTOMER_SALES_RECORD', customerSalesRequestResponse.data ? customerSalesRequestResponse.data.sales : [] || []);
  },
  FETCH_CURRENT_CUSTOMER_INVOICES_RECORDS: async function (context, payload) {
    //   Then fetch all invoices then match each customer invoices
    const allInvoicesHttpRequestData = {
      url: `/v1/invoices/businesses/${GET_USER_BUSINESS_ID()}`,
      method: 'GET',
      authRequest: true
    };

    const allInvoicesRequestResponse = await HTTP_REQUEST(
      allInvoicesHttpRequestData
    );

    const res = allInvoicesRequestResponse.data.invoice ? allInvoicesRequestResponse.data.invoice : [];
    const customerInvoices = res.filter((invoice) => invoice.client === payload._id);

    context.commit('SET_CUSTOMER_INVOICES_RECORD', customerInvoices);
  },
  UPDATE_LOYALTY: async function (context, payload) {
    const httpReq = {
      url: `/products/loyalty_points/${GET_USER_BUSINESS_ID()}/{points}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_UP_CUSTOMER_LOYALTY", httpRes);
  },
};

const mutations = {
  SET_UP_CUSTOMERS: (state, data) => {
    state.allCustomers = data;
  },
  SET_UP_CUSTOMERS_STATS_OVER_TIME: (state, data) => {
    state.customersStatsOverTime = data;
  },
  SETUP_CUSTOMER_CREATE_RES: (state, data) => {
    state.customerCreateRes = data;
  },
  SET_CUSTOMER_SALES_RECORD: (state, data) => {
    state.currentCustomerSalesRecords = data;
  },
  SET_CUSTOMER_INVOICES_RECORD: (state, data) => {
    state.currentCustomerInvoicesRecords = data;
  },
  SET_UP_CUSTOMER_LOYALTY_RES: (state, data) => {
    state.customerLoyaltyRes = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};

