import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import "./utils/validations";
import "./utils/components";
import "./utils/filters";
import "./assets/styles/index.css";
import Donut from "vue-css-donut-chart";
import "vue-css-donut-chart/dist/vcdonut.css";
import "zingchart/es6";
import zingchartVue from "zingchart-vue";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import VueTrip from "vue-trip";
import VueCarousel from "vue-carousel";

require("vue-trip/dist/vue-trip.css");
Vue.prototype.$toggleBodyClass = (addRemoveClass, className) => {
  const elBody = document.body;

  if (addRemoveClass === "addClass") {
    elBody.classList.add(className);
  } else {
    elBody.classList.remove(className);
  }
};

Vue.use(VueCarousel);
Vue.use(VueTrip);
Vue.use(PerfectScrollbar);
Vue.component("zingchart", zingchartVue);
Vue.use(Donut);
Vue.config.productionTip = false;

export const eventBus = new Vue();

new Vue({
  router,
  store,
  render: (h) => h(App),
  beforeCreate() {
    // document.body.style.zoom = "95%"
  },
}).$mount("#app");
