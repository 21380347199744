import {
  ADD_DATA_TO_DB,
  CONNECT_TO_DB,
  SAVE_OBJECT_STORE,
} from "../../browser-db-config/indexedDB";
import {
  ADD_AUTH_USER,
  GET_CURRENT_AUTH_USER,
  GET_LOCAL_DB_DATA,
  GET_USER_BUSINESS_DATA,
  GET_USER_BUSINESS_ID,
  GET_USER_TOKEN,
  SAVE_LOCAL_DB_DATA,
} from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {
  loginRes: null,
  businessUserRes: null,
  checkEmailRes: null,
  signUpRes: null,
  signUserBusinessUpRes: null,
};

const getters = {
  GET_LOGIN_RES: (state) => state.loginRes,
  GET_SIGN_UP_RES: (state) => state.signUpRes,
  FORGOT_PASSWORD_RES: (state) => state.forgotPasswordRes,
  GET_BUSINESS_USER_RES: (state) => state.businessUserRes,
  GET_CHECK_EMAIL_RES: (state) => state.checkEmailRes,
  GET_SIGN_USER_BUSINESS_UP_RES: (state) => state.signUserBusinessUpRes,
};
const actions = {
  LOG_USER_IN: async function (context, payload) {
    const httpReq = {
      url: "/v1/auth/login",
      method: "POST",
      authRequest: false,
      body: payload,
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    if (httpRes.status) {
      // Save a auth token date
      SAVE_LOCAL_DB_DATA("auth-token-date", new Date().toISOString());
    }
    context.commit("SETUP_LOGIN_RES", httpRes);
  },
  RECHECK_PASSWORD: async function (context, payload) {
    const httpReq = {
      url: "/v1/auth/login",
      method: "POST",
      authRequest: false,
      body: payload,
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    if (httpRes.status) {
      // Save a auth token date
      // SAVE_LOCAL_DB_DATA("auth-token-date", new Date().toISOString());
    }

    return httpRes;
  },
  DOWNLOAD_CUSTOMERS_POINTS: async function (context, payload) {
    SAVE_LOCAL_DB_DATA("secondary-auth-token", payload);

    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + payload.data.Token);
    myHeaders.append("Content-Type", "application/json;charset=utf-8");

    // Fetch Loyalty points
    fetch(
      `https://tractionloyalty-stage.herokuapp.com/customer_loyalty/${GET_USER_BUSINESS_ID()}`,
      {
        // method: 'POST',
        headers: myHeaders,
      }
    )
      .then((res) => res.json())
      .then(async (data) => {
        if (data.Success) {
          await SAVE_OBJECT_STORE(
            "offline_db_all_loyalty_points",
            "all_loyalty_points",
            data
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });

    // Fetch Store Credits
    fetch(
      `https://tractionloyalty-stage.herokuapp.com/customer_store_credit/${GET_USER_BUSINESS_ID()}`,
      {
        headers: myHeaders,
      }
    )
      .then((res) => res.json())
      .then(async (data) => {
        if (data.Success) {
          await SAVE_OBJECT_STORE(
            "offline_db_all_store_credits",
            "all_store_credits",
            data
          );
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  AUTO_LOGIN: async function (context, payload) {
    const httpReq = {
      url: "/v1/auth/refresh/token",
      method: "PUT",
      authRequest: false,
      body: {
        token: GET_USER_TOKEN(),
      },
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    if (httpRes.status) {
      const _generatedTokenTimestamp = new Date().toISOString();

      // update token
      SAVE_LOCAL_DB_DATA("auth-token-date", _generatedTokenTimestamp);

      const authUser = GET_LOCAL_DB_DATA("traction-app-user-data");
      authUser.token = httpRes.data.token;
      SAVE_LOCAL_DB_DATA("traction-app-user-data", authUser);

      // Save token and token timestamp for perssitent user login
      const loggedInUser = GET_CURRENT_AUTH_USER(authUser.user._id);
      loggedInUser.tokenTimeStamp = _generatedTokenTimestamp;
      loggedInUser.userData.token = authUser.token;
      ADD_AUTH_USER(loggedInUser);

      await SAVE_OBJECT_STORE("auth_data_db", "auth_data", authUser);
    }
  },
  CHECK_BUSINESS_STORE_CREDIT_IS_ACTIVE() {
    const token = GET_LOCAL_DB_DATA("secondary-auth-token").data.Token;

    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json;charset=utf-8");
    myHeaders.append("Authorization", `Bearer ${token}`);

    fetch(
      `https://tractionloyalty-stage.herokuapp.com/store_credit/${GET_USER_BUSINESS_ID()}`,
      {
        headers: myHeaders,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        const businessStoreCreditStatus = data.Data ? data.Data.active : false;

        let authData = GET_LOCAL_DB_DATA(
          "traction-app-current-user-business-data"
        );

        authData = {
          ...authData,
          store_credit_active: businessStoreCreditStatus,
        };

        SAVE_LOCAL_DB_DATA("traction-app-current-user-business-data", authData);
      })
      .catch((error) => {
        // console.log(error);
      });
  },
  FETCH_BUSINESS_USER_DETAILS: async function (context) {
    const httpReq = {
      url: "/v1/businesses/users/",
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_BUSINESS_USER_RES", httpRes);
    if (httpRes.status) {
      // send message to serviceWorker
      // Register Sync event in SW
      navigator.serviceWorker.ready.then((swReg) => {
        console.log(swReg);
        navigator.serviceWorker.controller.postMessage({
          business: httpRes.data,
        });
      });
    }
    return httpRes;
  },
  CHECK_EMAIL: async function (context, payload) {
    const httpReq = {
      url: `/v1/auth/check/${payload.email}`,
      method: "GET",
      authRequest: false,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_CHECK_EMAIL_RES", httpRes);
    return httpRes;
  },
  SIGN_USER_UP: async function (context, payload) {
    const httpReq = {
      url: `/v1/auth/signup`,
      method: "POST",
      authRequest: false,
      body: payload,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    if (httpRes.status) {
      // Save a auth token date
      SAVE_LOCAL_DB_DATA("auth-token-date", new Date().toISOString());
    }
    context.commit("SETUP_SIGN_UP_RES", httpRes);
  },
  SIGN_USER_BUSINESS_UP: async function (context, payload) {
    const httpReq = {
      url: "/v1/businesses/",
      method: "POST",
      authRequest: true,
      body: payload,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_SIGN_USER_BUSINESS_UP_RES", httpRes);
  },
  RESET_PASSWORD: async function (context, payload) {
    const httpReq = {
      url: "/v1/auth/recover/password",
      method: "PATCH",
      authRequest: false,
      body: payload,
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    context.commit("POPULATE_RES", httpRes);
  },
};
const mutations = {
  SETUP_LOGIN_RES: (state, data) => {
    state.loginRes = data;
  },
  SETUP_BUSINESS_USER_RES: (state, data) => {
    state.businessUserRes = data;
  },
  SETUP_CHECK_EMAIL_RES: (state, data) => {
    state.checkEmailRes = data;
  },
  SETUP_SIGN_UP_RES: (state, data) => {
    state.signUpRes = data;
  },
  SETUP_SIGN_USER_BUSINESS_UP_RES: (state, data) => {
    state.signUserBusinessUpRes = data;
  },
  POPULATE_RES: (state, data) => {
    state.forgotPasswordRes = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
