import { ADD_DATA_TO_DB, CONNECT_TO_DB, GET_DB_DATA, GET_OBJECT_STORE, SAVE_OBJECT_STORE } from '../../../browser-db-config/indexedDB';
import { GET_USER_BUSINESS_ID } from '../../../browser-db-config/localStorage';
import { HTTP_REQUEST } from '../../../http-config/trac-http-req';

const state = {
	jds: null,
	res: null,
	allRoles: null,
	allUsers: null,
};

const getters = {
	GET_ALL_JDS: (state) => state.jds,
	GET_ALL_ROLES: (state) => state.allRoles,
	GET_ALL_USERS: (state) => state.allUsers,
	GET_RES: (state) => state.res,
};

const actions = {
	FETCH_ALL_JDs: async function (context) {
		const httpReq = {
			url: `/acl/features`,
			method: 'GET',
			authRequest: true,
		};

		const httpRes = await HTTP_REQUEST(httpReq);

		context.commit('POPULATE_ALL_JDs', httpRes);
	},
	FETCH_ALL_USERS: async function (context) {
		// const offlineEmployeesDB = await CONNECT_TO_DB('offline_db_employees', 1, 'employees');
		let offlineEmployees = await GET_OBJECT_STORE('offline_db_employees', 'employees');

		const httpReq = {
			url: `/v1/businesses/${GET_USER_BUSINESS_ID()}/employees`,
			method: 'GET',
			authRequest: true,
		};

		HTTP_REQUEST(httpReq).then(httpRes => {
			offlineEmployees = httpRes;

			// Save data
			if (httpRes.status) {
				// Save assets to indexDB
				SAVE_OBJECT_STORE('offline_db_employees', 'employees', offlineEmployees);
				context.commit('POPULATE_ALL_USERS', offlineEmployees);
			}
		});

		if (!offlineEmployees) {
			offlineEmployees = await HTTP_REQUEST(httpReq);
		}
		context.commit('POPULATE_ALL_USERS', offlineEmployees);
		return offlineEmployees;
	},
	FETCH_USER: async function (context, payload) {
		const httpReq = {
			url: `/v1/auth/${payload.userId}`,
			method: 'GET',
			authRequest: true,
		};

		const htpRes = await HTTP_REQUEST(httpReq);

		// context.commit('POPULATE_USERS', htpRes.data ? htpRes.data : []);
	},
	FETCH_ALL_ROLES: async function (context) {
		const httpReq = {
			url: `/acl/roles/${GET_USER_BUSINESS_ID()}`,
			method: 'GET',
			authRequest: true,
		};

		const httpRes = await HTTP_REQUEST(httpReq);

		context.commit('POPULATE_ALL_ROLES', httpRes);
		return httpRes;
	},
	CREATE_ROLE: async function (context, payload) {
		const httpReq = {
			url: `/acl/roles`,
			method: 'POST',
			authRequest: true,
			body: payload,
		};

		const httpRes = await HTTP_REQUEST(httpReq);

		context.commit('REQ_ROLE_RES', httpRes);
	},
	UPDATE_ROLE: async function (context, payload) {
		const httpReq = {
			url: `/acl/roles`,
			method: 'PATCH',
			authRequest: true,
			body: payload,
		};

		const httpRes = await HTTP_REQUEST(httpReq);

		context.commit('REQ_ROLE_RES', httpRes);
	},
	CREATE_USER: async function (context, payload) {
		const httpReq = {
			url: `/v1/businesses/${GET_USER_BUSINESS_ID()}/employees`,
			method: 'POST',
			authRequest: true,
			body: payload,
		};

		const httpRes = await HTTP_REQUEST(httpReq);
		context.commit('REQ_ROLE_RES', httpRes);
		return httpRes;
	},
	UPDATE_USER: async function (context, payload) {
		const userID = payload._id;
		delete payload._id;
		const httpReq = {
			url: `/v1/auth/${userID}`,
			method: 'PATCH',
			authRequest: true,
			body: payload,
		};

		const httpRes = await HTTP_REQUEST(httpReq);

		context.commit('REQ_ROLE_RES', httpRes);
		return httpRes;
	},
	UPDATE_ACTIVE_USER: async function (context, payload) {
		const userID = payload._id;
		delete payload._id;
		const httpReq = {
			url: `/v1/auth/${userID}`,
			method: 'PATCH',
			authRequest: true,
			body: payload,
		};

		const httpRes = await HTTP_REQUEST(httpReq);

		context.commit('REQ_ROLE_RES', httpRes);
	},
};

const mutations = {
	POPULATE_ALL_JDs: (state, payload) => {
		state.jds = payload;
	},
	POPULATE_ALL_ROLES: (state, payload) => {
		state.allRoles = payload;
	},
	POPULATE_ALL_USERS: (state, payload) => {
		state.allUsers = payload;
	},
	REQ_ROLE_RES: (state, payload) => {
		state.res = payload;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
