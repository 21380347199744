<template>
  <main>
    <div class="flex justify-between items-center w-full">
      <div
        :class="{ 'flex items-center justify-between w-full relative': i !== steps }"
        v-for="i in steps"
        :key="i"
      >
        <div
          :class="
            i <= current
              ? ' bg-primaryBlue text-white'
              : 'bg-accentLight step-blue'
          "
          class="w-10 h-10 step-blue transition-all duration-500 ease-in-out rounded-full z-10 inline-flex items-center justify-center"
        >
          {{ i }}
        </div>
        <div v-if="i !== steps" class="border-t border-dashed w-full absolute"></div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  props: {
    steps: {
      default: 3,
      type: Number
    },
    current: {
      default: 1,
      type: Number,
    },
  },
  mounted() {
    if (this.current > this.steps) throw "your current cannot be bigger than steps"
  }


}
</script>

<style>
.step-blue {
  color: rgba(37, 59, 149, 0.35);
}
</style>