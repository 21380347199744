<template>
  <main
    class="
      z-50
      fixed
      shadow-sm
      w-full
      top-0
      h-16
      px-2
      md:pl-10 md:pr-16
      bg-white
      items-center
      flex
      justify-between
      non-print-slip
    "
  >
    <div class="flex">
      <!-- <div class="w-5 mr-3 flex items-center">
        <svg
          @click="toggle"
          class="w-5 h-5 cursor-pointer absolute md:hidden block mx-1"
          viewBox="0 0 25 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect y="0.425293" width="15" height="2.5" rx="1.25" fill="#253B95" />
          <rect y="7.92529" width="21" height="2.5" rx="1.25" fill="#253B95" />
          <rect y="15.4253" width="25" height="2.5" rx="1.25" fill="#253B95" />
        </svg>
      </div> -->

      <div class="w-5 mr-3 flex items-center" id="sidebar-switch">
        <svg
          v-if="!active"
          @click="toggle"
          class="w-5 h-5 cursor-pointer absolute md:hidden block mx-1"
          viewBox="0 0 25 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect y="0.425293" width="15" height="2.5" rx="1.25" fill="#253B95" />
          <rect y="7.92529" width="21" height="2.5" rx="1.25" fill="#253B95" />
          <rect y="15.4253" width="25" height="2.5" rx="1.25" fill="#253B95" />
        </svg>
        <svg
          v-else
          @click="toggle"
          class="w-4 h-4 cursor-pointer absolute"
          viewBox="0 0 23 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.2 2L2 21.2"
            stroke="#979797"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2 2L21.2 21.2"
            stroke="#979797"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <!-- 
      <svg
        class="block mx-1 cursor-pointer md:hidden"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#003283"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <line x1="3" y1="12" x2="21" y2="12"></line>
        <line x1="3" y1="6" x2="21" y2="6"></line>
        <line x1="3" y1="18" x2="21" y2="18"></line>
      </svg> -->
      <div @click="$router.push('/')" class="flex items-center cursor-pointer">
        <img
          src="@/assets/svg/otherLogo.svg"
          alt="Traction Logo"
          class="block ml-2"
        />
        <h2 class="text-xs pl-2 pt-2 font-medium text-primaryBlue">
          traction apps
        </h2>
      </div>
    </div>

    <div class="flex items-center">
      <div class="text-xs mr-6"></div>
      <div
        class="
          smallest
          h-8
          w-8
          flex
          justify-center
          items-center
          rounded-full
          mr-4
          bg-accentLight
        "
      >
        <div class="tiny text-accentDark cursor-pointer uppercase">
          {{ nameInitials }}
        </div>
      </div>
      <div class="text-xs">
        <select name="" id="" class="p-3" @change="selectUser">
          <option value="">Add Another User</option>
          <option
            class="capitalize"
            :selected="authUser.user._id === user._id"
            v-for="authUser in authUsers"
            :key="authUser.user._id"
            :value="authUser.user._id"
          >
            {{ authUser.user.name }}
          </option>
        </select>
      </div>
      <!-- <div
        class="uppercase cursor-pointer tiny pl-2 text-primaryBlue pr-5"
        v-if="user"
      >
        {{ user.name }}
      </div> -->
      <div v-if="false" class="flex items-center">
        <svg
          class="w-6 cursor-pointer h-6"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 6C0 2.68629 2.68629 0 6 0H24C27.3137 0 30 2.68629 30 6V24C30 27.3137 27.3137 30 24 30H6C2.68629 30 0 27.3137 0 24V6Z"
            fill="#253B95"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M22.7669 19.0782V21.3057C22.7686 21.7238 22.594 22.1233 22.2858 22.406C21.9777 22.6886 21.5647 22.8283 21.1483 22.7907C18.8635 22.5424 16.6688 21.7617 14.7405 20.5112C12.9465 19.3712 11.4255 17.8502 10.2855 16.0562C9.03067 14.1192 8.24975 11.9138 8.00603 9.61872C7.96852 9.20357 8.10721 8.79177 8.38825 8.4839C8.66928 8.17604 9.06676 8.00047 9.48361 8.00007H11.7111C12.4565 7.99274 13.0918 8.5391 13.1961 9.27717C13.2901 9.99002 13.4645 10.6899 13.7159 11.3636C13.9198 11.9063 13.7894 12.518 13.3817 12.9303L12.4388 13.8732C13.4957 15.7321 15.0349 17.2713 16.8938 18.3282L17.8367 17.3853C18.249 16.9776 18.8607 16.8472 19.4034 17.0511C20.0771 17.3025 20.777 17.4769 21.4898 17.5709C22.2365 17.6762 22.7856 18.3244 22.7669 19.0782Z"
            stroke="white"
            stroke-width="0.99"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <svg
          class="w-6 cursor-pointer h-6 ml-4"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 6C0 2.68629 2.68629 0 6 0H24C27.3137 0 30 2.68629 30 6V24C30 27.3137 27.3137 30 24 30H6C2.68629 30 0 27.3137 0 24V6Z"
            fill="#253B95"
          />
          <path
            d="M19.3425 12.1579C19.3425 9.80346 17.4338 7.89478 15.0793 7.89478C12.7248 7.89478 10.8161 9.80346 10.8161 12.1579C10.8161 17.1316 8.68457 18.5527 8.68457 18.5527H21.474C21.474 18.5527 19.3425 17.1316 19.3425 12.1579"
            stroke="white"
            stroke-width="1.26316"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16.1166 21.396C15.8624 21.8343 15.3941 22.104 14.8874 22.104C14.3808 22.104 13.9124 21.8343 13.6582 21.396"
            stroke="white"
            stroke-width="1.26316"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <circle
            cx="20.1848"
            cy="9.89453"
            r="3.81567"
            fill="#00B8CD"
            stroke="#253B95"
            stroke-width="0.575399"
          />
        </svg>
      </div>
    </div>
  </main>
</template>

<script>
import { CLEAR_LIST, SAVE_OBJECT_STORE } from "../../browser-db-config/indexedDB";
import { FETCH_ALL_DATA } from '../../browser-db-config/utilityMethods';
import {
  DELETE_AUTH_USER,
  GET_CURRENT_AUTH_USER,
  GET_USER_DATA,
  GET_USER_TOKEN,
  ITERATEALL_ALL_AUTH_USERS,
  SAVE_LOCAL_DB_DATA,
} from "./../../browser-db-config/localStorage";
import { eventBus } from "./../../main";

export default {
  data() {
    return {
      user: GET_USER_DATA(),
      active: false,
      authUsers: null,
    };
  },
  watch: {
    $route(x) {
      if (x) {
        if (screen.width < 640 && this.active) {
          this.active = !this.active;
        }
      }
    },
  },
  created() {
    this.authUsers = ITERATEALL_ALL_AUTH_USERS();
    this.fetchLoyaltyPointToken();
  },
  computed: {
    nameInitials() {
      if (!this.user) {
        return;
      }
      if (this.user.name) {
        return this.user.name
          .split(" ")
          .map((name) => name.substring(0, 1))
          .join("");
      }
    },
  },
  methods: {
    toggle() {
      this.active = !this.active;
      this.$emit("toggle", { active: this.active });
    },
    selectUser(event) {
      const selectedUserID = event.target.value;

      if (selectedUserID === "") {
        this.$router.replace({ name: "Login" });
        return;
      }

      const selectedUser = GET_CURRENT_AUTH_USER(selectedUserID);

      if (!selectedUser.tokenTimeStamp) {
        eventBus.$emit("trac-alert", {
          message:
            "Logged you out because of an update. Kindly log back in and you'd be fine",
        });

        this.logUserOut();
        return;
      }

      SAVE_LOCAL_DB_DATA("traction-app-user-data", selectedUser.userData);
      SAVE_LOCAL_DB_DATA(
        "traction-app-current-user-business-data",
        selectedUser.businessData
      );
      SAVE_LOCAL_DB_DATA("auth-token-date", selectedUser.tokenTimeStamp);

      this.user = GET_USER_DATA();
      // Inital logic
      window.location.reload();
    },
    async logUserOut() {
      // this.$ClearGlobalOfflineManager();
      // Clear data in indexedDB
      CLEAR_LIST();
      // localStorage.clear();
      DELETE_AUTH_USER();
      sessionStorage.clear();
    },
    fetchLoyaltyPointToken() {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Content-Type", "application/json;charset=utf-8");
      myHeaders.append("Authorization", "Bearer " + GET_USER_TOKEN());

      // Fetch Token
      fetch(`https://tractionapp-stage.herokuapp.com/auth/loyalty/access`, {
        method: "POST",
        headers: myHeaders,
      })
        .then((res) => res.json())
        .then(async (data) => {
          this.$store.dispatch("DOWNLOAD_CUSTOMERS_POINTS", data);
          FETCH_ALL_DATA();
          await SAVE_OBJECT_STORE(
            "secondary_token_db",
            "secondary_token",
            data.data
          );

          // Check is store credit for buiness is active
          this.$store.dispatch("CHECK_BUSINESS_STORE_CREDIT_IS_ACTIVE");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.tiny {
  font-size: 0.7rem;
}

@media print {
  .non-print-slip {
    display: none;
  }
}
</style>
