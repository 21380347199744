import { ADD_DATA_TO_DB, CONNECT_TO_DB, GET_DB_DATA, GET_OBJECT_STORE, SAVE_OBJECT_STORE } from "../../browser-db-config/indexedDB";
import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {
  allInvoices: null,
  invocieRes: null,
};

const getters = {
  GET_ALL_INVOICES: (state) => state.allInvoices,
  GET_INVOICE_RES: (state) => state.invocieRes
};

const actions = {
  FETCH_ALL_INVOICES: async function (context) {
    // fetch from db (not-synced)

    // fetch from db
    let offlineInvoices = await GET_OBJECT_STORE('offline_db_invoices', 'invoices');

    // Fetch from server
    const httpReq = {
      url: `/v1/invoices/businesses/${GET_USER_BUSINESS_ID()}`,
      method: "GET",
      authRequest: true,
    };


    HTTP_REQUEST(httpReq).then(httpRes => {
      if (httpRes.status) {
        // Save assets to indexDB
        SAVE_OBJECT_STORE('offline_db_invoices', 'invoices', httpRes);
      }
      context.commit('SET_UP_ALL_INVOICES', httpRes);
    });

    if (!offlineInvoices) {
      offlineInvoices = await HTTP_REQUEST(httpReq);
    }

    context.commit('SET_UP_ALL_INVOICES', offlineInvoices);
  },
  FETCH_INVOICE: async function (context, payload) {
    // Fetch from db

    // If invoice found no need to fetch it from server
    const httpReq = {
      url: `/v1/invoices/${payload}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    context.commit('SET_UP_INVOICE_RES', httpRes);
  },
  REQUEST_INVOICE_PAYMENT: async function (context, payload) {
    const httpReq = {
      url: `/v1/invoices/businesses/${GET_USER_BUSINESS_ID()}/${payload.invoice_id
        }/payment/${payload.payment_method}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit('SET_UP_INVOICE_RES', httpRes);
  },
  CREATE_INVOICE: async function (context, payload) {
    const httpReq = {
      url: `/v1/invoices/`,
      method: "POST",
      authRequest: true,
      body: payload,
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    if (httpRes.status) {
      // fetch from db
      // const offlineInvoicesDB = await CONNECT_TO_DB('offline_db_invoices', 1, 'invoices');
      // let offlineInvoices = await GET_DB_DATA(offlineInvoicesDB, 'invoices');

      // offlineInvoices = null;

      // if (!offlineInvoices) {
      //   offlineInvoices = {
      //     data: {
      //       invoice: []
      //     }
      //   };
      // }

      // offlineInvoices.data.invoice.unshift(httpRes.data.invoice);
      // await ADD_DATA_TO_DB(offlineInvoicesDB, 'invoices', offlineInvoices);
    }

    context.commit('SET_UP_INVOICE_RES', httpRes);
  },
  UPDATE_INVOICE: async function (context, payload) {
    // fetch from db
    // const offlineInvoicesDB = await CONNECT_TO_DB('offline_db_invoices', 1, 'invoices');
    // let offlineInvoices = await GET_DB_DATA(offlineInvoicesDB, 'invoices');

    // if (!offlineInvoices) {
    //   offlineInvoices = {
    //     data: {
    //       invoice: []
    //     }
    //   };
    // }

    const httpReq = {
      url: `/v1/invoices/${payload._id}`,
      method: "PATCH",
      authRequest: true,
      body: payload,
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    if (httpRes.status) {
      // offlineInvoices.data.invoice.forEach(inv => {
      //   if (inv._id === httpRes.data.invoice._id) {
      //     inv = httpRes.data.invoice;
      //   }
      // });

      // await ADD_DATA_TO_DB(offlineInvoicesDB, 'invoices', offlineInvoices);
    }

    context.commit('SET_UP_INVOICE_RES', httpRes);
  },
  DELETE_INVOICE: async function (context, payload) {
    // fetch from db
    // const offlineInvoicesDB = await CONNECT_TO_DB('offline_db_invoices', 1, 'invoices');
    // let offlineInvoices = await GET_DB_DATA(offlineInvoicesDB, 'invoices');


    // if (!offlineInvoices) {
    //   offlineInvoices = {
    //     data: {
    //       invoice: []
    //     }
    //   };
    // }

    const httpReq = {
      url: `/v1/invoices/${payload._id}`,
      method: "DELETE",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    // if (httpRes.status) {
    //   offlineInvoices.data.invoice = offlineInvoices.data.invoice.filter(inv => inv._id !== payload._id);

    //   await ADD_DATA_TO_DB(offlineInvoicesDB, 'invoices', offlineInvoices);
    // }


    context.commit('SET_UP_INVOICE_RES', httpRes);
  },
  SEND_INVOICE: async function (context, payload) {
    const httpReq = {
      url: "/v1/invoices/actions/send",
      method: "POST",
      authRequest: true,
      body: payload,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit('SET_UP_INVOICE_RES', httpRes);
  },
  UPDATE_MILESTONE: async function (context, payload) {
    const httpReq = {
      url: `/v1/invoices/${payload.invoiceID}/milestones/${payload.milestone._id}`,
      method: "PATCH",
      authRequest: true,
      body: {
        status:
          payload.milestone.status,
      },
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit('SET_UP_INVOICE_RES', httpRes);
  },
  UPDATE_PAYMENT: async function (context, payload) {
    const httpReq = {
      url: `/v1/invoices/${payload.invoiceID}/payments/log`,
      method: "PUT",
      authRequest: true,
      body: payload.body,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit('SET_UP_INVOICE_RES', httpRes);
  },
  UPDATE_INVOICE_FULFILLMENT: async function (context, payload) {
    const httpReq = {
      url: `/v1/invoices/${payload._id}/orderstatus`,
      method: "PATCH",
      authRequest: true,
      body: { order_status: payload.order_status, order_note: '' },
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit('SET_UP_INVOICE_RES', httpRes);
  },
};

const mutations = {
  SET_UP_ALL_INVOICES: (state, data) => {
    state.allInvoices = data;
  },
  SET_UP_INVOICE_RES: (state, data) => {
    state.invocieRes = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
