import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {
  uploadRes: null,
};

const getters = {
  GET_UPLOAD_RES: (state) => state.uploadRes,
};

const actions = {
  UPLOAD_FILE: async function(context, payload) {
    const uploadFormData = new FormData();
    uploadFormData.append("file", payload.file);
    // Setup image upload

    const httpReq = {
      url: `/v1/uploads/`,
      method: "POST",
      authRequest: true,
      body: uploadFormData,
      fileUpload: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    context.commit("SETUP_FILE_UPLOADED", httpRes);
    return httpRes;
  },
};

const mutations = {
  SETUP_FILE_UPLOADED: (state, data) => {
    state.uploadRes = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
