import Vue from "vue";

Vue.filter("formatPrice", (value, currencySign = true) => {
  if (!value) return (currencySign ? "₦" : "NGN") + " " + "0.0";
  let val =
    (currencySign ? "₦" : "NGN") +
    " " +
    (value / 1).toFixed(2).replace(",", ".");
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
});

Vue.filter("formartTime", (dateISO) => {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return new Date(dateISO).toLocaleDateString(undefined, options);
});

Vue.filter('serialNumberFormat', serialNumber => {
  const num = serialNumber.toString();
  const pad = '000';
  return pad.substring(0, pad.length - num.length) + num;
});

Vue.filter('truncate', (text, length, clamp) => {
  clamp = clamp || '...';
  var node = document.createElement('div');
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
});
