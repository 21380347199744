import { SAVE_OBJECT_STORE } from "../../browser-db-config/indexedDB";
import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {};

const getters = {};

const actions = {
  // FETCH_ALL_PAYMENTS: async function(context, payload) {
  // 	const httpReq = {
  // 		url: `/v1/kyc/verify/phoneemail`,
  // 		method: 'GET',
  // 		authRequest: true,
  // 	};

  // 	const httpRes = await HTTP_REQUEST(httpReq);
  // 	return httpRes;
  // },
  SEND_OTP: async function(context, payload) {
    const httpReq = {
      url: `/v1/kyc/otp/send`,
      method: "POST",
      authRequest: true,
      body: payload,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  BANK_LIST: async function() {
    const httpReq = {
      url: "/v1/payments/gettractionbanklist",
      method: "GET",
      authRequest: true,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  VERIFY_BANK_DETAILS: async function(context, payload) {
    const httpReq = {
      url: `/v1/payments/gettractionnameenquiry/bankcode/${payload.bankCode}/account/${payload.accountNumber}`,
      method: "GET",
      authRequest: true,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  VERIFY_KYC: async function(context, payload) {
    const httpReq = {
      url: `/v1/kyc/verify/${GET_USER_BUSINESS_ID()}/status`,
      method: "GET",
      authRequest: true,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  VERIFY_DOCUMENT: async function(context, payload) {
    const httpReq = {
      url: `/v1/businesses/${GET_USER_BUSINESS_ID()}/verify-kyc`,
      method: "POST",
      authRequest: true,
      body: payload,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  VERIFY_OTP: async function(context, payload) {
    const httpReq = {
      url: `/v1/kyc/verify/phoneemail`,
      method: "POST",
      authRequest: true,
      body: payload,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  VERIFY_BVN: async function(context, payload) {
    const httpReq = {
      url: `/v1/kyc/verify/${GET_USER_BUSINESS_ID()}/document`,
      method: "POST",
      authRequest: true,
      body: payload,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  VERIFY_ACCOUNT: async function(context, payload) {
    const httpReq = {
      url: `/v1/kyc/verify/${GET_USER_BUSINESS_ID()}/account`,
      method: "POST",
      authRequest: true,
      body: payload,
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    // if (httpRes.status) {
    //   console.log(httpRes);
    // 	await SAVE_OBJECT_STORE('offline_db_bankAccounts', 'bankAccounts', httpRes);
    // }

    return httpRes;
  },

  // GET_JSON_PAYMENT: async function(context, payload) {
  //     const httpReq = {
  //         url: `/v1/payments/reports/paymentlog/businesses/${GET_USER_BUSINESS_ID()}?startdate=${
  // 			payload.startDate
  // 		}&enddate=${payload.endDate}`,
  //         method: 'POST',
  //         authRequest: true,
  //         body: payload.storeIds,
  //     };

  //     const httpRes = await HTTP_REQUEST(httpReq);
  //     return httpRes;
  // },
};
const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
