import { HTTP_REQUEST } from "../../http-config/trac-http-req";
import { ADD_DATA_TO_DB, CONNECT_TO_DB, GET_DB_DATA, GET_OBJECT_STORE, SAVE_OBJECT_STORE } from "../../browser-db-config/indexedDB";
import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";

const state = {
};

const getters = {
};

const actions = {
  REFRESH_ALL_CUSTOMERS: async function (context) {
    // fetch from db
    // fetch from db (not-synced)
    // const notSyncedOfflineCustomersDB = await CONNECT_TO_DB('not_synced_offline_customers', 1, 'not_synced_customers');
    let notSyncedOfflineCustomers = await GET_OBJECT_STORE('not_synced_offline_customers', 'not_synced_customers');

    if (!notSyncedOfflineCustomers) {
      notSyncedOfflineCustomers = [];
    }

    // Open and get offline customers
    // const offlineCustomersDB = await CONNECT_TO_DB('offline_db_customers', 1, 'customers');
    let offlineCustomers = await GET_OBJECT_STORE("offline_db_customers", 'customers');

    //  Check if data was found in indexedDB
    if (offlineCustomers) {
      offlineCustomers.data.items = [...offlineCustomers.data.items, ...notSyncedOfflineCustomers];
    } else {
      // So Request url condition doesn't break
      offlineCustomers = {
        data: {}
      };
    }

    // Setup request
    const httpReq = {
      url: `/v2/customers/businesses/${GET_USER_BUSINESS_ID()}${offlineCustomers.data.metaData
        ? "?updated_at=" + offlineCustomers.data.metaData.lastUpdate
        : ""
        }`,
      method: "GET",
      authRequest: true,
    };

    // Make request
    const httpRes = await HTTP_REQUEST(httpReq);

    if (httpRes.status && httpRes.data) {
      if (httpRes.data.items) {
        offlineCustomers.data.items = offlineCustomers.data.items || [];
        offlineCustomers.data.items.forEach((offlineCustomer, i) => {
          httpRes.data.items.forEach((serverData, j) => {
            if (serverData._id === offlineCustomer._id) {
              offlineCustomers.data.items[i] = offlineCustomer;
              httpRes.data.items.splice(j, 1);
            }
          });
        });
        if ((httpRes.data.items).length > 0) {
          offlineCustomers.data.items.push(...httpRes.data.items);
        }
      }

      offlineCustomers.data.metaData = httpRes.data.items ? httpRes.data.metaData : offlineCustomers.data.metaData;
      offlineCustomers.status = httpRes.status ? httpRes.status : offlineCustomers.status;

      // Save assets to indexDB
      await SAVE_OBJECT_STORE('offline_db_customers', 'customers', offlineCustomers);

    } else {
      offlineCustomers.data.items = notSyncedOfflineCustomers;
    }
  },
  REFRESH_ALL_PRODUCTS: async function (context) {
    // Fetch not synced products
    // Fetch synced offline products || {}

    // if (!offlineProducts) {
    //   // So Request url condition doesn't break
    //   offlineProducts = {};
    // }

    // Open and get offline products
    // const offlineProductsDB = await CONNECT_TO_DB('offline_db_products', 1, 'products');
    let offlineProducts = await GET_OBJECT_STORE('offline_db_products', 'products');

    if (offlineProducts) {
    } else {
      // So Request url condition doesn't break
      offlineProducts = {};
    }

    // Fetch products form server
    const httpReq = {
      url: `/v2/businesses/${GET_USER_BUSINESS_ID()}/products${offlineProducts.metaData
        ? "?updated_at=" + offlineProducts.metaData.lastUpdate
        : ""
        }`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    // Sort out the local products data and the newly fetched ones
    const resProducts = httpRes.data ? httpRes.data.items ? httpRes.data.items : [] : [];

    // Edit the local data
    if (offlineProducts.items) {
      let matchNotFoundFlag = true;

      offlineProducts.items.forEach((offlineItem, index) => {
        resProducts.forEach((fetchedItem) => {
          if (fetchedItem._id === offlineItem._id) {
            matchNotFoundFlag = false;
            offlineProducts.items[index] = { ...fetchedItem };
          } else if (matchNotFoundFlag && index + 1 === offlineProducts.items.length) {
            offlineProducts.items.push({ ...fetchedItem, wc_sync_status: true });
          }
        });
      })
    } else {
      offlineProducts.items = httpRes.data.items;
    }

    // Check for deleted products
    if (httpRes.data.metaData.recordsRemoved) {
      offlineProducts.items = offlineProducts.items.filter(prod => !httpRes.data.metaData.recordsRemoved.includes(prod._id));
    }

    offlineProducts.metaData = httpRes.data ? httpRes.data.metaData : offlineProducts.metaData;
    offlineProducts.status = httpRes.status ? httpRes.status : offlineProducts.status;

    if (httpRes.status) {
      // Save assets to indexDB
      await SAVE_OBJECT_STORE('offline_db_products', 'products', offlineProducts);
    }
  },
  REFRESH_SALES_HISTORY: async function (context, payload = { _id: 'all' }) {
    // const notSyncedOfflineSalesDB = await CONNECT_TO_DB('not_synced_offline_sales', 1, 'not_synced_sales');
    let notSyncedOfflineSales = await GET_OBJECT_STORE('not_synced_offline_sales', 'not_synced_sales');

    // Open and get offline sales history
    // const offlineSalesHistoryDB = await CONNECT_TO_DB('offline_db_sales_history', 1, 'sales_history');
    let offlineSalesHistory = await GET_OBJECT_STORE('offline_db_sales_history', 'sales_history');

    if (offlineSalesHistory) {
      // offlineSalesHistory

      if (!offlineSalesHistory.data) {
        offlineSalesHistory = {
          data: {}
        };
      }
      
    } else {
      // So Request url condition doesn't break
      offlineSalesHistory = {
        data: {}
      };
    }

    const httpReq = {
      url: `/v1/sales/businesses/${GET_USER_BUSINESS_ID()}/${payload._id}${offlineSalesHistory.data.metaData
        ? "?updated_at=" + offlineSalesHistory.data.metaData.lastUpdate
        : ""
        }`,
      method: 'GET',
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    if (offlineSalesHistory.data.salesHistory) {
      if (httpRes.data) {
        // Update the list
        offlineSalesHistory.data.salesHistory.unshift(...httpRes.data.salesHistory);

        // Update the metadata
        offlineSalesHistory.data.metaData = httpRes.data.metaData || offlineSalesHistory.data.metaData;
      }
    } else {
      offlineSalesHistory.data = httpRes.data || {};
    }

    if (httpRes.status) {
      // Save assets to indexDB
      await SAVE_OBJECT_STORE('offline_db_sales_history', 'sales_history', offlineSalesHistory);
    }
  },
  REFRESH_ALL_INVOICES: async function (context) {
    // fetch from db (not-synced)

    // Fetch from server
    const httpReq = {
      url: `/v1/invoices/businesses/${GET_USER_BUSINESS_ID()}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    if (httpRes.status) {
      // fetch from db
    // const offlineInvoicesDB = await CONNECT_TO_DB('offline_db_invoices', 1, 'invoices');
    // let offlineInvoices = await GET_DB_DATA('offline_db_invoices', 'invoices');

      // Save assets to indexDB
      await SAVE_OBJECT_STORE('offline_db_invoices', 'invoices', httpRes);
    }
  },
};

const mutations = {

};

export default {
  state,
  getters,
  actions,
  mutations,
};
