import store from './../store/index';
import { ADD_DATA_TO_DB, CONNECT_TO_DB, GET_DB_DATA, GET_OBJECT_STORE, SAVE_OBJECT_STORE } from './indexedDB';

function GENERATE_RANDOM_ID(length = 18) {
  return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(length);
  });

}

function MAKE_ID(l) {
  let text = '';
  let charList = 'abcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < l; i++) {
    text += charList.charAt(Math.floor(Math.random() * charList.length));
  }

  if (offlineIDs.includes(text)) {
    text = '';
    return makeid(l);
  } else {
    offlineIDs.push(text);
    return text;
  }
}
async function setupStoreCustomerPoints() {
  // const offlineLoyaltyPointsDB = await CONNECT_TO_DB(
  //   "offline_db_all_loyalty_points",
  //   1,
  //   "all_loyalty_points"
  // );
  let offlineLoyaltyPoints = await GET_OBJECT_STORE(
    "offline_db_all_loyalty_points",
    "all_loyalty_points"
  );


  if (!offlineLoyaltyPoints) {
    await SAVE_OBJECT_STORE(
      "offline_db_all_loyalty_points",
      "all_loyalty_points",
      {
        Data: [],
        Success: true,
        Message: '',
        Errors: ''
      }
    );
  }

  // fetch from db for not synced data
  // const notSyncedOfflineLoyaltyPointsDB = await CONNECT_TO_DB(
  //   "not_synced_offline_loyalty_points",
  //   1,
  //   "offline_loyalty_points"
  // );

  let notSyncedofflineLoyaltyPoints =
    (await GET_OBJECT_STORE(
      'not_synced_offline_loyalty_points',
      "offline_loyalty_points"
    ));


  if (!notSyncedofflineLoyaltyPoints) {
    await SAVE_OBJECT_STORE(
      'not_synced_offline_loyalty_points',
      "offline_loyalty_points",
      []
    );
  }

  // const offlineStoreCreditDB = await CONNECT_TO_DB(
  //   "offline_db_all_store_credits",
  //   1,
  //   "all_store_credits"
  // );

  let offlineStoreCredit = await GET_OBJECT_STORE(
    'offline_db_all_store_credits',
    "all_store_credits"
  );

  if (!offlineStoreCredit) {
    await SAVE_OBJECT_STORE(
      'offline_db_all_store_credits',
      "all_store_credits",
      {
        Data: [],
        Success: true,
        Message: '',
        Errors: ''
      }
    );
  }

  // fetch from db for not synced data
  // const notSyncedOfflineStoreCreditsDB = await CONNECT_TO_DB(
  //   "not_synced_offline_store_credits",
  //   1,
  //   "offline_store_credits"
  // );

  let notSyncedOfflineStoreCredits =
    (await GET_OBJECT_STORE(
      'not_synced_offline_store_credits',
      "offline_store_credits"
    ));

  if (!notSyncedOfflineStoreCredits) {
    await SAVE_OBJECT_STORE(
      'not_synced_offline_store_credits',
      "offline_store_credits",
      []
    );
  }
}


function FETCH_ALL_DATA() {
  store.dispatch("FETCH_ALL_CATEGORIES");
  store.dispatch("CUSTOMER_PAYMENT_REQUEST");
  store.dispatch("FETCH_ALL_PRODUCTS");
  store.dispatch("FETCH_ALL_CUSTOMERS");
  setupStoreCustomerPoints();
}

export { GENERATE_RANDOM_ID, MAKE_ID, FETCH_ALL_DATA };
