<template>
  <main>
    <label class="relative w-auto">
      <textarea
        :disabled="disabled"
        class="border py-5 text-accentDark leading-6 mt-x pl-5 w-full text-xs rounded input-area"
        placeholder=" "
        rows="4"
        @input="$emit('input', $event.target.value)"
        :value="value"
      />
      <span class="text-xs leading-5 text-primaryBlue">{{ placeholder }}</span>
    </label>
  </main>
</template>

<script>
import DisableAutocomplete from 'vue-disable-autocomplete';
export default {
  props: ['placeholder', 'disabled', 'value'],


}
</script>

<style scoped>
span {
  pointer-events: none;
  position: absolute;
  left: 1rem;
  top: -6rem;
  transition: 0.2s;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity: 0.5;
}

.input-area:focus + span,
.input-area:not(:placeholder-shown) + span {
  opacity: 1;
  transform: scale(0.75) translateY(-100%) translateX(-30px);
  left: 1.6rem;
  top: -7.2rem;
  background: white;
  padding: 0 5px;
  font-size: 0.9rem;
  text-transform: uppercase;
}
.input-area:focus + .input-area:-webkit-autofill + span {
  opacity: 0;
}
.input-area:-webkit-autofill .input-area:placeholder-shown {
  opacity: 0 !important;
}
</style>