import { GET_USER_BUSINESS_ID } from "../../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../../http-config/trac-http-req";


const state = {
  allBeneficiaries: null,
  allBanks: null,
};

const getters = {
  GET_ALL_BENEFICIARIES: (state) => state.allBeneficiaries,
  GET_ALL_BENFICIARY_BANKS: (state) => state.allBanks,
};

const actions = {
  FETCH_ALL_BENEFICIARIES: async function (context) {
    // Always fetching stores
    const httpReq = {
      url: `/api/beneficiary/getBeneficiaryByUser/${GET_USER_BUSINESS_ID()}`,
      method: "GET",
      authRequest: true,
    };

    // Fetch online
    const httpRes = await HTTP_REQUEST(httpReq);

    context.commit("SETUP_ALL_BENFICIARIES", httpRes);
  },
  FETCH_BENFICIARY_BANK_DETAILS: async function (context, payload) {
    // Always fetching stores
    const httpReq = {
      url: `/api/EWTraction/SearchBeneficiary`,
      method: "POST",
      authRequest: true,
      body: payload,
    };

    // Fetch online
    const httpRes = await HTTP_REQUEST(httpReq);

    return httpRes;
  },
  FETCH_ALL_BENEFICIARIES_BANKS: async function (context) {
    // Always fetching stores
    const httpReq = {
      url: `api/EWTraction/banklist`,
      method: "POST",
      authRequest: true,
    };

    // Fetch online
    const httpRes = await HTTP_REQUEST(httpReq);

    context.commit("SETUP_ALL_BENFICIARIES_BANKS", httpRes);
  },
  CREATE_BENEFICIARIES: async function (context, payload) {
    // Always fetching stores
    const httpReq = {
      url: `api/Beneficiary/addBeneficiary`,
      method: "POST",
      authRequest: true,
      body: payload,
    };

    // Fetch online
    const httpRes = await HTTP_REQUEST(httpReq);

    if (httpRes.status) {
      context.commit('UPDATE_ALL_BENEFICIARIES', httpRes.data);
    }

    return httpRes;
  },
  DELETE_BENEFICIARIES: async function (context, payload) {
    // Always fetching stores
    const httpReq = {
      url: `api/Beneficiary/deleteBeneficiary/?beneficiaryId=${payload.benficiaryID}&userGuid=${payload.userid}`,
      method: "DELETE",
      authRequest: true,
    };

    // Fetch online
    const httpRes = await HTTP_REQUEST(httpReq);

    if (httpRes.status) {
      context.commit('UPDATE_DELETED_BENEFICIARIES', payload);
    }

    return httpRes;
  },
  CREATE_BENEFICIARY: async function (context, payload) {
    // // fetch from db
    // // const offlineStoresDB = await CONNECT_TO_DB('offline_db_stores', 1, 'offline-stores');
    // // let offlineStores = await GET_DB_DATA(offlineStoresDB, 'offline-stores');
    // let offlineStores = await GET_OBJECT_STORE('offline_db_stores', 'offline-stores');

    // if (!offlineStores) {
    //   offlineStores = {
    //     data: []
    //   };
    // }

    // const httpReq = {
    //   url: `/v1/stores/`,
    //   method: "POST",
    //   authRequest: true,
    //   body: payload,
    // };

    // const httpRes = await HTTP_REQUEST(httpReq);

    // if (httpRes.status) {
    //   offlineStores.data.push(httpRes.data);
    //   // await ADD_DATA_TO_DB(offlineStoresDB, 'offline-stores', offlineStores);
    //   await SAVE_OBJECT_STORE('offline_db_stores', 'offline-stores', offlineStores);
    // }

    // context.commit("STORE_ACTION_RES", httpRes);
  },
  UPDATE_BENEFICIARY: async function (context, payload) {
    // // fetch from db
    // // const offlineStoresDB = await CONNECT_TO_DB('offline_db_stores', 1, 'offline-stores');
    // // let offlineStores = await GET_DB_DATA(offlineStoresDB, 'offline-stores');
    // let offlineStores = await GET_OBJECT_STORE('offline_db_stores', 'offline-stores');


    // const httpReq = {
    //   url: `/v1/stores/${payload._id}`,
    //   method: "PATCH",
    //   authRequest: true,
    //   body: payload,
    // };

    // const httpRes = await HTTP_REQUEST(httpReq);

    // if (httpRes.status) {
    //   offlineStores.data = offlineStores.data.map(store => {
    //     if (store._id === payload._id) {
    //       store = httpRes.data;
    //     }

    //     return store;
    //   });
    //   // await ADD_DATA_TO_DB(offlineStoresDB, 'offline-stores', offlineStores);
    //   await SAVE_OBJECT_STORE('offline_db_stores', 'offline-stores', offlineStores);
    // }

    // context.commit("STORE_ACTION_RES", httpRes);
  },
};

const mutations = {
  SETUP_ALL_BENFICIARIES: (state, data) => {
    state.allBeneficiaries = data;
  },
  SETUP_ALL_BENFICIARIES_BANKS: (state, data) => {
    state.allBanks = data;
  },
  UPDATE_ALL_BENEFICIARIES: (state, data) => {
    state.allBeneficiaries.data.push(data);
  },
  UPDATE_DELETED_BENEFICIARIES: (state, data) => {
    state.allBeneficiaries.data = state.allBeneficiaries.data.filter(beneficiary => beneficiary.beneficiaryGuid !== data.benficiaryID);
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
