function SAVE_LOCAL_DB_DATA(storageName, item) {
  const data = JSON.stringify(item);
  return sessionStorage.setItem(storageName, data);
}

function DELETE_LOCAL_DB_DATA(storageName) {
  sessionStorage.removeItem(storageName);
}

function GET_LOCAL_DB_DATA(storageName, deleteData = false) {
  const data = sessionStorage.getItem(storageName);
  if (deleteData) {
    DELETE_LOCAL_DB_DATA(storageName);
  }
  return JSON.parse(data);
}

function GET_USER_TOKEN() {
  let userData = GET_LOCAL_DB_DATA("traction-app-user-data");
  return userData ? userData.token : null;
}

function GET_USER_DATA() {
  let userData = GET_LOCAL_DB_DATA("traction-app-user-data");
  if (userData) {
    return userData ? userData.user : {};
  }
}

function GET_USER_BUSINESS_ID() {
  let userBusinessData = GET_LOCAL_DB_DATA(
    "traction-app-current-user-business-data"
  );
  if (userBusinessData) {
    return userBusinessData._id;
  }
}

function GET_USER_BUSINESS_DATA() {
  let userBusinessData = GET_LOCAL_DB_DATA(
    "traction-app-current-user-business-data"
  );
  if (userBusinessData) {
    return userBusinessData;
  }
}

// New Auth user storage system
function GET_ALL_AUTH_USERS() {
  const allAuthUsers = localStorage.getItem("all-auth-users");
  return JSON.parse(allAuthUsers) || {};
}

function GET_CURRENT_AUTH_USER(selectedUserID) {
  const allAuthUsers = GET_ALL_AUTH_USERS();
  const authUser = allAuthUsers[selectedUserID];
  return authUser;
}

function ADD_AUTH_USER(authData) {
  const allAuthUsers = GET_ALL_AUTH_USERS();
  // allAuthUsers.push(authData);
  allAuthUsers[GET_USER_DATA()["_id"]] = authData;
  SAVE_ALL_USERS(allAuthUsers);
}

function SAVE_ALL_USERS(allAuthUsers) {
  localStorage.setItem("all-auth-users", JSON.stringify(allAuthUsers));
}

function DELETE_AUTH_USER() {
  const allAuthUsers = GET_ALL_AUTH_USERS();
  delete allAuthUsers[`${GET_USER_DATA()._id}`];
  localStorage.setItem("all-auth-users", JSON.stringify(allAuthUsers));
}

function ITERATEALL_ALL_AUTH_USERS() {
  const allUsers = [];

  const allAuthUsers = GET_ALL_AUTH_USERS();
  for (const key in allAuthUsers) {
    if (Object.hasOwnProperty.call(allAuthUsers, key)) {
      const element = allAuthUsers[key];
      allUsers.push(element["userData"]);
    }
  }

  return allUsers;
}

export {
  SAVE_LOCAL_DB_DATA,
  GET_USER_BUSINESS_DATA,
  GET_LOCAL_DB_DATA,
  GET_USER_DATA,
  GET_USER_TOKEN,
  GET_USER_BUSINESS_ID,
  DELETE_LOCAL_DB_DATA,
  // New Auth user storage system
  GET_ALL_AUTH_USERS,
  ADD_AUTH_USER,
  DELETE_AUTH_USER,
  ITERATEALL_ALL_AUTH_USERS,
  GET_CURRENT_AUTH_USER,
};
