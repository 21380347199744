// Parse an empty string ('') to the neededProperty if you don't know what to
add // Don't add a default parameter to the neededProperty prop
<template>
  <div class="relative w-full">
    <button
      :disabled="disabled"
      type="button"
      class="z-10 relative flex items-center focus:outline-none select-none w-full"
      @click="open = !open"
    >
      <!-- <slot name="button"></slot> -->
      <span
        class="px-4 py-2 leading-6 border rounded inline-flex justify-between items-center text-sm w-full"
      >
        <input
          v-model="myInput"
          disabled
          class="bg-transparent mr-2 text-xs text-gray-500 capitalize cursor-pointer w-full"
        />

        <svg
          class="w-4 h-4 opacity-50"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M4.516 7.548c.436-.446 1.043-.481 1.576 0L10 11.295l3.908-3.747c.533-.481 1.141-.446 1.574 0 .436.445.408 1.197 0 1.615-.406.418-4.695 4.502-4.695 4.502a1.095 1.095 0 0 1-1.576 0S4.924 9.581 4.516 9.163c-.409-.418-.436-1.17 0-1.615z"
          />
        </svg>
      </span>
    </button>

    <!-- to close when clicked on space around it in desktop-->
    <button
      type="button"
      class="fixed inset-0 h-full w-full cursor-default focus:outline-none"
      v-if="open"
      @click="open = false"
      tabindex="-1"
    ></button>

    <!--dropdown content: desktop-->
    <transition
      enter-active-class="transition-all duration-200 ease-out"
      leave-active-class="transition-all duration-750 ease-in"
      enter-class="opacity-0 scale-75"
      enter-to-class="opacity-100 scale-100"
      leave-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-75"
    >
      <div
        class="selecet-dropdown overflow-scroll block absolute z-30 shadow-lg border w-full rounded py-1 text-xs mt-2 bg-white"
        :class="placement === 'right' ? 'right-0' : 'left-0'"
        v-if="open && !customDropdown"
      >
        <!-- Search bar -->
        <div class="bg-gray-200 p-2" v-if="shouldFilter">
          <input
            class="w-full p-2"
            type="text"
            name=""
            id=""
            placeholder="Search customer"
            @input="$emit('filter-list', $event.target.value)"
          />
        </div>
        <div class="p-2 px-4" v-if="addProp">
          <button class="block w-full text-left text-primaryBlue" @click="$emit('add-prop')">{{ addPropText }}</button>
        </div>
        <!-- <slot name="content"></slot> -->
        <button
          type="button"
          v-for="(option, i) in options"
          :key="i"
          @click="select(option)"
          class="capitalize flex w-full justify-between items-center rounded px-4 py-1 my-1 hover:bg-primaryBlue hover:text-white"
          :class="option[neededProperty] === myInput ? 'bg-accentLight' : ''"
        >
          {{ option[neededProperty] }}
        </button>
      </div>
      <div class="" v-if="open && customDropdown">
        <trac-date-picker
          class="absolute top-auto z-20 bg-white"
          ref="calender"
          :open="open"
          @change="setDate"
          format="YYYY-MM-DD"
          v-if="open"
          :append-to-body="false"
          :inline="true"
          v-model="dateInput"
        ></trac-date-picker>
      </div>
    </transition>
    <div
      class="md:hidden fixed w-full h-full inset-0 bg-gray-900 opacity-50 z-10"
      @click="open = false"
      v-if="open"
    ></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: false,
      myInput: this.selector,
      dateInput: null,
    };
  },
  props: {
    placement: {
      type: String,
      default: "right",
      validator: (value) => ["right", "left"].indexOf(value) !== -1,
    },
    selector: {
      type: String,
      default: "Select Store",
    },
    addPropText: {
      type: String,
      default: 'Add Customer'
    },
    customDropdown: {
      type: Boolean,
      default: false,
    },
    neededProperty: {
      type: String,
      required: true,
    },
    shouldFilter: {
      type: Boolean,
      default: false,
    },
    addProp: {
      type: Boolean,
      default: false,
    },
    inputDate: {
      default: null,
    },
    options: {
      type: Array,
      //   default: [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.dateInput = this.inputDate;

    const onEscape = (e) => {
      if (e.key === "Esc" || e.key === "Escape") {
        this.open = false;
      }
    };

    document.addEventListener("keydown", onEscape);

    this.$once("hook:beforeDestroy", () => {
      document.removeEventListener("keydown", onEscape);
    });
  },
  methods: {
    select(options) {
      this.myInput = options[this.neededProperty];
      this.open = false;
      this.$emit("optionSelected", options);
    },
    setDate(date) {
      this.myInput = new Date(date).toLocaleDateString();
      this.$emit("selectDate", date.toISOString());
      this.open = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.selecet-dropdown {
  max-height: 400px;
}
</style>
