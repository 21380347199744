import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {
  inventoryStatsOverTime: null,
};

const getters = {
  GET_INVENTORY_STATS: (state) => state.inventoryStatsOverTime,
};
const actions = {
  FETCH_INVENTORY_STATS_OVER_TIME_PERIOD: async function(context, payload) {
    const httpReq = {
      url: `/v1/dashboard/inventory/revenue/${GET_USER_BUSINESS_ID()}/${
        payload.startDate
      }/${payload.endDate}/${payload.storeID}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    const stateArg = "inventoryStatsOverTime";
    context.commit("SETUP_RES", { stateArg, httpRes });
  },
};
const mutations = {
  SETUP_RES: (state, payload) => {
    state[payload.stateArg] = payload.httpRes;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
