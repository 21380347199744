const permissionsModules = [
  {
    parent: {
      name: "Home Page",
      permittedRolesIDs: [],
      isActiveForUser: true,
      permissionsForUser: {
        creditNote: true,
        invoiceLog: true,
        // =============================Done
        mostRescentSales: true,
        wallet: true,
        accountDetails: true,
        accountBalance: true,
        addFunds: true,
        airtimeAndBills: true,
        viewAccountNumber: true,
        totalSalesAmount: true,
        totalSalesItems: true,
      },
    },
    submodules: [
      {
        name: "View Wallet Card on Home Page",
        permittedRolesIDs: [],
      },
      {
        name: "View Account Details",
        permittedRolesIDs: [],
      },
      {
        name: "View Account Balance",
        permittedRolesIDs: [],
      },
      {
        name: "Add Fund",
        permittedRolesIDs: [],
      },
      {
        name: "Make Transfer",
        permittedRolesIDs: [],
      },
      {
        name: "Buy Airtime/Paybills",
        permittedRolesIDs: [],
      },
      {
        name: "View Account number",
        permittedRolesIDs: [],
      },
      {
        name: "Invoice Log",
        permittedRolesIDs: [],
      },
      {
        name: "Credit Note",
        permittedRolesIDs: [],
      },
      {
        name: "Total Amount Sales",
        permittedRolesIDs: [],
      },
      {
        name: "Total Amount of item sold",
        permittedRolesIDs: [],
      },
      {
        name: "Most Recent Sales",
        permittedRolesIDs: [],
      },
    ],
  },
  {
    parent: {
      name: "Create & Modify Invoices",
      permittedRolesIDs: [],
      isActiveForUser: true,
      permissionsForUser: {
        createInvoice: true,
        deleteInvoice: true,
        editInvoice: true,
        resendInvoice: true,
        requestPayment: true,
        shareInvoice: true,
        duplicateInvoice: true,
        viewInvoice: true,
        downloadInvoice: true,
        // =============================Done
      },
    },
    submodules: [
      {
        name: "Creating an invoice",
        permittedRolesIDs: [],
      },
      {
        name: "Deleting an Invoice.",
        permittedRolesIDs: [],
      },
      {
        name: "Sharing invoice",
        permittedRolesIDs: [],
      },
      {
        name: "Duplicating an invoice",
        permittedRolesIDs: [],
      },
      {
        name: "Resending an invoice",
        permittedRolesIDs: [],
      },
      {
        name: "Editing an invoice",
        permittedRolesIDs: [],
      },
      {
        name: "Requesting payment for invoice",
        permittedRolesIDs: [],
      },
      {
        name: "View Invoices",
        permittedRolesIDs: [],
      },
      {
        name: "Downloading an invoice",
        permittedRolesIDs: [],
      },
    ],
  },
  {
    parent: {
      name: "Transcations",
      permittedRolesIDs: [],
      isActiveForUser: true,
      permissionsForUser: {
        // =============================Done
        viewAndManagePayments: true,
        viewAndManagePayouts: true,
      },
    },
    submodules: [
      {
        name: "View & Manage Payments",
        permittedRolesIDs: [],
      },
      {
        name: "View & Manage Payouts",
        permittedRolesIDs: [],
      },
    ],
  },
  {
    parent: {
      name: "Wallet and Accounts",
      permittedRolesIDs: [],
      isActiveForUser: true,
      permissionsForUser: {
        // =============================Done
        createWallet: true,
        viewWalletDetails: true,
        transferFunds: true,
        manageBeneficiaries: true,
        buyAirtimeAndBills: true,
        viewTrasactionsHistoryAndStatements: true,
      },
    },
    submodules: [
      {
        name: "Create Wallet",
        permittedRolesIDs: [],
      },
      {
        name: "View Wallet details and balance",
        permittedRolesIDs: [],
      },
      {
        name: "Transfer funds",
        permittedRolesIDs: [],
      },
      {
        name: "Manage beneficiaries",
        permittedRolesIDs: [],
      },
      {
        name: "Buy Airtime/Bills",
        permittedRolesIDs: [],
      },
      {
        name: "View transaction history & statements",
        permittedRolesIDs: [],
      },
    ],
  },
  {
    parent: {
      name: "Create, Edit Products & Categorie",
      permittedRolesIDs: [],
      isActiveForUser: true,
      permissionsForUser: {
        createProduct: true,
        deleteProduct: true,
        editProduct: true,
        createCategory: true,
        editCategory: true,
        deleteCategory: true,
        // =============================Done
        viewProducts: true,
        viewCategory: true,
      },
    },
    submodules: [
      {
        name: "Creating a product",
        permittedRolesIDs: [],
      },
      {
        name: "Deleting a product",
        permittedRolesIDs: [],
      },
      {
        name: "Edit Product",
        permittedRolesIDs: [],
      },
      {
        name: "Create Categories",
        permittedRolesIDs: [],
      },
      {
        name: "Edit Categories",
        permittedRolesIDs: [],
      },
      {
        name: "Delete Categories",
        permittedRolesIDs: [],
      },
      {
        name: "View Products",
        permittedRolesIDs: [],
      },
      {
        name: "View products & inventory",
        permittedRolesIDs: [],
      },
      {
        name: "View Categories",
        permittedRolesIDs: [],
      },
    ],
  },
  {
    parent: {
      name: "Inventory Management",
      permittedRolesIDs: [],
      isActiveForUser: true,
      permissionsForUser: {

      },
    },
    submodules: [
      {
        name: "Initiate stock counts",
        permittedRolesIDs: [],
      },
      {
        name: "View stock count",
        permittedRolesIDs: [],
      },
      {
        name: "Adjust stock levels",
        permittedRolesIDs: [],
      },
      {
        name: "View stock adjustment log",
        permittedRolesIDs: [],
      },
      {
        name: "Initiate inventory transfer",
        permittedRolesIDs: [],
      },
      {
        name: "View & Receive inventory transfer",
        permittedRolesIDs: [],
      },
      {
        name: "Print Labels",
        permittedRolesIDs: [],
      },
    ],
  },
  {
    parent: {
      name: "Point of sale",
      permittedRolesIDs: [],
  isActiveForUser: true,
      permissionsForUser: {

      },
    },
    submodules: [
      {
        name: "Make sale on point of sale",
        permittedRolesIDs: [],
      },
      {
        name: "Give discounts or adjust prices",
        permittedRolesIDs: [],
      },
      {
        name: "Manage tickets",
        permittedRolesIDs: [],
      },
    ],
  },
  {
    parent: {
      name: "Sales History",
      permittedRolesIDs: [],
  isActiveForUser: true,
      permissionsForUser: {

      },
    },
    submodules: [
      {
        name: "View Sales History",
        permittedRolesIDs: [],
      },
      {
        name: "Re-issue receipts",
        permittedRolesIDs: [],
      },
    ],
  },
  {
    parent: {
      name: "Online store",
      permittedRolesIDs: [],
  isActiveForUser: true,
      permissionsForUser: {

      },
    },
    submodules: [
      {
        name: "Manage online catalog",
        permittedRolesIDs: [],
      },
      {
        name: "View orders",
        permittedRolesIDs: [],
      },
      {
        name: "Manage orders (update status, add fees, send request)",
        permittedRolesIDs: [],
      },
      {
        name: "Manage shipping fees",
        permittedRolesIDs: [],
      },
    ],
  },
  {
    parent: {
      name: "Customers",
      permittedRolesIDs: [],
  isActiveForUser: true,
      permissionsForUser: {

      },
    },
    submodules: [
      {
        name: "Create and Manage customers",
        permittedRolesIDs: [],
      },
      {
        name: "View Customer Details",
        permittedRolesIDs: [],
      },
    ],
  },
  // {
  //   parent: {
  //     name: "Insights",
  //     permittedRolesIDs: [],
  // isActiveForUser: true,
  //     permissionsForUser: {

  //     },
  //   },
  //   submodules: [
  //     {
  //       name: "",
  //       permittedRolesIDs: [],
  //     },
  //   ],
  // },
  {
    parent: {
      name: "Settings",
      permittedRolesIDs: [],
  isActiveForUser: true,
      permissionsForUser: {

      },
    },
    submodules: [
      {
        name: "Manage Business Information",
        permittedRolesIDs: [],
      },
      {
        name: "Manage users & Roles",
        permittedRolesIDs: [],
      },
      {
        name: "Manage Stores",
        permittedRolesIDs: [],
      },
      {
        name: "Manage payment Methods",
        permittedRolesIDs: [],
      },
      {
        name: "Enable offline mode",
        permittedRolesIDs: [],
      },
      {
        name: "Manage bank account",
        permittedRolesIDs: [],
      },
      {
        name: "Manage Payment & Settlement Settings",
        permittedRolesIDs: [],
      },
    ],
  },
];

export { permissionsModules };