import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {
  allRegisters: null,
};

const getters = {
  GET_ALL_REGISTERS: (state) => state.allRegisters,
};

const actions = {
  FETCH_ALL_REGISTERS: async function(context, payload) {
    const httpReq = {
      url: `/v1/registries/businesses/${GET_USER_BUSINESS_ID()}?store_id=${
        payload._id
      }`,
      method: "GET",
      authRequest: true,
    };

    let httpRes = await HTTP_REQUEST(httpReq);

    context.commit("SETUP_REGISTERS", httpRes);

    // If error occured
  },
  FETCH_REGISTRY: async function(context, payload) {
    const httpReq = {
      url: `/v1/registries/${payload}`,
      method: 'GET',
      authRequest: true
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    // context.commit('POPULATE_REGISTRY', httpRes);
  },
  FETCH_REGISTRY: async function(context, payload) {
    const httpReq = {
      url: `/v1/registries/${payload}`,
      method: 'GET',
      authRequest: true
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit('POPULATE_REGISTRY', httpRes);
  },
  ADD_CASH: async function(context, payload) {
    const httpReq = {
      url: `/v1/registries/${context.state.registry._id}/cash/add`,
      method: 'PUT',
      authRequest: true,
      body: payload
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    // context.commit('POPULATE_REGISTRY', addCashToRegisterRequestResponse.data);
  },
  REMOVE_CASH: async function(context, payload) {
    const httpReq = {
      url: `/v1/registries/${context.state.registry._id}/cash/remove`,
      method: 'PUT',
      authRequest: true,
      body: payload
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    // context.commit('POPULATE_REGISTRY', removeCashToRegisterRequestResponse.data);
  },
  OPEN_REGISTER: async function(context, payload) {
    const _id = payload._id;

    const httpReq = {
      url: `/v1/registries/${context.state.registry._id}/open`,
      method: 'PUT',
      authRequest: true,
      body: payload.payload
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    // context.commit('SWITCH_REGISTER_OPEN_STATE', { ...openRegisterRequestResponse.data, _id });
  },
  CLOSE_REGISTER: async function(context, payload) {
    const httpReq = {
      url: `/v1/registries/${context.state.registry._id}/close`,
      method: 'PUT',
      authRequest: true,
      body: payload
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    // context.commit('SWITCH_REGISTER_CLOSE_STATE', closeRegisterRequestResponse.data);
  },
  FETCH_ACTIVE_USERS: async function(context) {
    const httpReq = {
      url: `/v1/registries/businesses/${GET_USER_BUSINESS_ID()}/employees`,
      method: 'GET',
      authRequest: true
    };

    let httpRes = await HTTP_REQUEST(httpReq);
  },
  SWITCH_REGISTRY_USER: async function(context, payload) {
    const httpReq = {
      url: `/v1/registries/${payload._id}`,
      method: 'PATCH',
      authRequest: true,
      body: payload
    };

    const httpRes = await HTTP_REQUEST(
      httpReq
    );
  }
};

const mutations = {
  SETUP_REGISTERS: (state, data) => {
    state.allRegisters = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
