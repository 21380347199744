<template>
  <main>
    <div>
      <label
        :class="{ 'disable ': disabled }"
        :for="id"
        class="flex items-center cursor-pointer"
      >
        <!-- toggle -->
        <div class="relative">
          <input
            :checked="active"
            @change="newUpdate"
            :disabled="disabled"
            :id="id"
            type="checkbox"
            class="hidden"
          />

          <div
            class="toggle__line w-6 h-3 bg-gray-400 rounded-full shadow-inner"
          ></div>

          <div
            class="toggle__dot absolute w-4 h-4 bg-gray-600 rounded-full shadow inset-y-0 left-0"
          ></div>
        </div>
        <!-- label -->
        <div class="ml-3 text-xs">
          <slot />
        </div>
      </label>
    </div>
  </main>
</template>

<script>
export default {
  props: {
    active: {
      // type: Function,
      default: false,
    },
    id: {
      type: String,
      default: () => {
        return 'toggle-' + Math.random().toString(36).substr(2, 9);
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    newUpdate() {
      this.$emit('newval', this.active);
    }
  }

}
</script>

<style scoped>
.toggle__dot {
  top: -0.125rem;
  left: -0.25rem;
  transition: all 0.3s ease-in-out;
}

input:checked ~ .toggle__dot {
  transform: translateX(100%);
  background-color: #253b95;
}
input:checked ~ .toggle__line {
  transition: all 0.3s ease-in-out;
  background: #cbdaea;
}
.disable {
  cursor: not-allowed !important;
}
</style>