// Note::: This component requires an array of objects that has a name property
in it.

<template>
  <div class="relative dropdown-gradient rounded-lg">
    <button
      class="z-10 relative flex items-center focus:outline-none select-none w-full"
      @click="open = !open"
    >
      <!-- <slot name="button"></slot> -->
      <span
        class="px-4 py-2 leading-6 border rounded inline-flex justify-between items-center text-sm w-full"
      >
        <img v-if="selectedOption" :src="getIcon(selectedOption)" />
        <input
          v-model="myInput"
          disabled
          class="bg-transparent mr-8 cursor-pointer uppercase text-sm text-white"
        />

        <svg
          class="w-4 h-4 opacity-50"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="white"
        >
          <path
            d="M4.516 7.548c.436-.446 1.043-.481 1.576 0L10 11.295l3.908-3.747c.533-.481 1.141-.446 1.574 0 .436.445.408 1.197 0 1.615-.406.418-4.695 4.502-4.695 4.502a1.095 1.095 0 0 1-1.576 0S4.924 9.581 4.516 9.163c-.409-.418-.436-1.17 0-1.615z"
          />
        </svg>
      </span>
    </button>

    <!-- to close when clicked on space around it in desktop-->
    <button
      class="fixed inset-0 h-full w-full cursor-default focus:outline-none"
      v-if="open"
      @click="open = false"
      tabindex="-1"
    ></button>
    <!--dropdown content: desktop-->
    <transition
      enter-active-class="transition-all duration-200 ease-out"
      leave-active-class="transition-all duration-750 ease-in"
      enter-class="opacity-0 scale-75"
      enter-to-class="opacity-100 scale-100"
      leave-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-75"
    >
      <div
        class="block absolute z-30 shadow-lg border w-full rounded  text-xs mt-2 bg-white"
        :class="placement === 'right' ? 'right-0' : 'left-0'"
        v-if="open"
      >
        <!-- <slot name="content"></slot> -->

        <button
          v-for="(option, i) in options"
          :key="i"
          @click="select(option)"
          class="uppercase text-base font-medium text-primaryGray border-b flex w-full justify-between items-center rounded px-2 py-2 my-1 hover:bg-primaryBlue hover:text-white"
        >
          <img :src="getIcon(option)" />
          <span class="mr-auto ml-4 text-xs">{{ option.name }}</span>
        </button>
      </div>
    </transition>
    <div
      class="md:hidden fixed w-full h-full inset-0 bg-gray-900 opacity-50 z-10"
      @click="open = false"
      v-if="open"
    ></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: false,
      myInput: `Select ${this.selector}`,
    };
  },
  props: {
    placement: {
      type: String,
      default: "right",
      validator: (value) => ["right", "left"].indexOf(value) !== -1,
    },
    selector: {
      type: String,
      default: "Store",
    },
    options: {
      type: Array,
      //   default: [],
    },
    selectedOption: {
      type: Object,
    },
  },
  mounted() {
    const onEscape = (e) => {
      if (e.key === "Esc" || e.key === "Escape") {
        this.open = false;
      }
    };

    // if(this.selectedOption) {
    setTimeout(() => this.select(this.selectedOption), 500);
    // }
    document.addEventListener("keydown", onEscape);

    this.$once("hook:beforeDestroy", () => {
      document.removeEventListener("keydown", onEscape);
    });
  },
  methods: {
    select(options) {
      this.myInput = options.name;
      this.open = false;
      this.$emit("optionSelected", options);
    },
    getIcon(option) {
      return require(`../../assets/svg/${option.icon}`);
    },
  },
};
</script>

<style scoped>
.dropdown-gradient {
  background: linear-gradient(180deg, #0143aa 0%, #003283 100%);
}
</style>
