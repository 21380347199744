import Vue from "vue";
import VueRouter from "vue-router";
import {
  GET_LOCAL_DB_DATA,
  GET_ALL_AUTH_USERS,
  ITERATEALL_ALL_AUTH_USERS,
  SAVE_LOCAL_DB_DATA,
  GET_CURRENT_AUTH_USER,
} from "../browser-db-config/localStorage";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login"),
    meta: {
      hideForAuth: true,
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("../views/ForgotPassword"),
    meta: {
      hideForAuth: true,
    },
  },
  {
    path: "/signup",
    name: "Signup",
    component: () => import("../views/Signup"),
    meta: {
      hideForAuth: true,
    },
  },
  {
    path: "/view-invoice",
    name: "ViewInvoiceWithoutAuth",
    component: () =>
      import("../views/invoices/invoice-view/viewInvoiceDetails"),
  },
  {
    path: "/signup/fcmb/:email",
    name: "FcmbSignUp",
    component: () => import("../views/FcmbSignup"),
    meta: {
      hideForAuth: true,
    },
  },
  {
    path: "/fcmb-login",
    name: "FcmbLogin",
    component: () => import("../views/FcmbLogin"),
    meta: {
      hideForAuth: true,
    },
  },
  {
    path: "/components",
    name: "Components",
    // route level code-splitting
    // this generates a separate chunk (components.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "components" */ "../views/Components.vue"),
  },
  {
    path: "/",
    component: Home,
    redirect: { name: "Dashboard" },
    children: [
      // ======================================================================   Dashboard
      {
        path: "dashboard",
        name: "Dashboard",
        component: () => import("../views/dashboard/Dashboard"),
      },
      // ======================================================================   Kyc
      {
        path: "kyc",
        component: () => import("../views/kyc/Main"),
        redirect: { name: "kyc" },
        children: [
          {
            path: "",
            name: "Kyc",
            component: () => import("../views/kyc/Kyc"),
          },
          {
            path: "verify-otp",
            name: "VerifyOtp",
            component: () => import("../views/kyc/VerifyOtp"),
          },
          {
            path: "verify-email",
            name: "VerifyEmail",
            component: () => import("../views/kyc/VerifyEmail"),
          },
          {
            path: "verify-phone",
            name: "VerifyPhone",
            component: () => import("../views/kyc/VerifyPhone"),
          },
          {
            path: "verify-phone-otp",
            name: "VerifyPhoneOtp",
            component: () => import("../views/kyc/VerifyPhoneOtp"),
          },

          {
            path: "add-bank-details",
            name: "AddBankDetails",
            component: () => import("../views/kyc/AddBankDetails"),
          },

          {
            path: "existing-phone-email",
            name: "ExistingPhoneEmail",
            component: () => import("../views/kyc/ExistingPhoneEmail"),
          },
          {
            path: "update-phone",
            name: "UpdateNumber",
            component: () => import("../views/kyc/UpdateNumber"),
          },
          {
            path: "photo",
            name: "Photo",
            component: () => import("../views/kyc/Photo"),
          },
          {
            path: "add-document",
            name: "AddDocument",
            component: () => import("../views/kyc/AddDocument"),
          },
          {
            path: "verify-document/:type",
            name: "VerifyDocument",
            component: () => import("../views/kyc/VerifyDocument"),
          },
          {
            path: "verify-bvn",
            name: "VerifyBvn",
            component: () => import("../views/kyc/VerifyBvn"),
          },
        ],
      },
      // ======================================================================   PIN Management

      {
        path: "settlement-preference",
        component: () =>
          import("../views/kyc/pin-mangement/PinManagemntRouter"),
        children: [
          {
            path: "",
            name: "SettlementPreference",
            component: () =>
              import("../views/kyc/pin-mangement/SettlementPreference"),
          },
          {
            path: "pin-management",
            name: "PinManagment",
            component: () => import("../views/kyc/pin-mangement/PinManagement"),
          },
          {
            path: "confirm-password",
            name: "ConfirmPassword",
            component: () =>
              import("../views/kyc/pin-mangement/ConfirmPassword"),
          },
          {
            path: "confirm-pin",
            name: "ConfirmPinInput",
            component: () => import("../views/kyc/pin-mangement/Pin"),
          },
          {
            path: "add-settlement-preference",
            name: "AddSettlementPreference",
            component: () =>
              import("../views/kyc/pin-mangement/AddSettlementPreference"),
          },
        ],
      },
      // ======================================================================   Insights

      {
        path: "insights",
        name: "Insights",
        component: () => import("../views/dashboard/Insights"),
      },
      {
        path: "view-insight",
        name: "ViewInsights",
        component: () => import("../views/dashboard/InsightsTwo"),
      },
      {
        path: "tickets",
        name: "Tickets",
        component: () => import("../views/tickets/Tickets"),
      },
      {
        path: "view-ticket",
        name: "ViewTicket",
        component: () => import("../views/tickets/ViewTicket"),
      },
      // ======================================================================   Payments
      {
        path: "payments",
        component: () => import("../views/Payments/Payment"),
        redirect: { name: "Payments" },
        children: [
          {
            path: "",
            name: "Payments",
            component: () => import("../views/Payments/MainPayments"),
          },
          {
            path: "payouts",
            name: "Payouts",
            component: () => import("../views/Payments/Payouts"),
          },
          {
            path: "payout-details/:payoutId",
            name: "PayoutDetails",
            component: () => import("../views/Payments/PayoutDetails"),
          },
        ],
      },
      // ======================================================================   Wallet
      {
        path: "wallet",
        component: () => import("../views/wallet/Wallet"),
        redirect: { name: "MainWallet" },
        children: [
          {
            path: "",
            name: "MainWallet",
            component: () => import("../views/wallet/MainWallet"),
          },
          {
            path: "wallet-transaction-details",
            name: "walletTransactionDetails",
            component: () => import("../views/wallet/WalletTransactionDetail"),
          },
          {
            path: "wallet-transaction-details-template",
            name: "walletTransactionDetailsTemplate",
            component: () =>
              import("../views/wallet/WalletTransactionDetailTemplate"),
          },
          {
            path: "beneficiaries",
            name: "WalletBeneficiaries",
            component: () =>
              import("../views/wallet/beneficiaries/Beneficiaries"),
          },
          {
            path: "wallet-transfer",
            name: "WalletMainTransfer",
            component: () => import("../views/wallet/WalletTransfer"),
          },
          {
            path: "open-account",
            name: "OpenAccount",
            component: () => import("../views/wallet/OpenAccount"),
          },
          {
            path: "create-pin",
            name: "CreatePin",
            component: () => import("../views/wallet/CreatePin"),
          },

          {
            path: "confirm-pin",
            name: "ConfirmPin",
            component: () => import("../views/wallet/ConfirmPin"),
          },
          {
            path: "account-details",
            name: "AccountDetails",
            component: () => import("../views/wallet/AccountDetails"),
          },
        ],
      },
      // ======================================================================   Customers
      {
        path: "/customers",
        name: "Customers",
        component: () => import("../views/customers/Customers"),
      },
      {
        path: "/add-customer",
        name: "AddCustomer",
        component: () => import("../views/customers/AddCustomer"),
      },
      {
        path: "/customer-details",
        name: "CustomerDetails",
        component: () => import("../views/customers/CustomerDetails"),
      },
      {
        path: "/customer-loyalty",
        name: "CustomerLoyalty",
        component: () => import("../views/customers/CustomerLoyalty"),
      },
      // ======================================================================   Products
      {
        path: "/products",
        name: "Products",
        component: () => import("../views/products/Products"),
      },
      {
        path: "/add-product",
        name: "AddProduct",
        component: () => import("../views/products/AddProduct"),
      },
      {
        path: "/bulk-products-upload",
        name: "BulkProductsUpload",
        component: () => import("../views/products/BulkProductUpload"),
      },
      {
        path: "/uploaded-batches",
        name: "UploadedBatches",
        component: () => import("../views/products/AllUploadBatches"),
      },
      {
        path: "/products-upload-failed/:batchNumber",
        name: "ProductFailedToUpload",
        component: () => import("../views/products/ProductFailedToUpload"),
      },
      // ======================================================================   Categories
      {
        path: "/categories",
        name: "Categories",
        component: () => import("../views/categories/Categories"),
      },
      {
        path: "/add-categories",
        name: "AddCategories",
        component: () => import("../views/categories/AddCategories"),
      },
      {
        path: "/categories-list",
        name: "CategoriesList",
        component: () => import("../views/categories/CategoriesList"),
      },
      // ======================================================================   POS
      {
        path: "/pos",
        name: "Pos",
        component: () => import("../views/pos/pos"),
      },
      {
        path: "/pos-dashboard",
        name: "PosDashboard",
        component: () => import("../views/pos/PosDashboard"),
      },
      {
        path: "/pos-payment",
        name: "PosPayment",
        component: () => import("../views/pos/PosPayment"),
      },
      // {
      //   path: "/digital-goods",
      //   name: "DigitalGoods",
      //   component: () => import("../views/pos/DigitalGoods"),
      // },
      // ======================================================================   Registers
      {
        path: "/registers",
        name: "Registers",
        component: () => import("../views/registers/Registers"),
      },
      {
        path: "/registers-details",
        name: "RegistersDetails",
        component: () => import("../views/registers/RegistersDetails"),
      },
      // ======================================================================   Invoices
      {
        path: "/invoices",
        name: "Invoices",
        component: () => import("../views/invoices/Invoices"),
      },
      {
        path: "/add-invoice",
        name: "AddInvoice",
        component: () => import("../views/invoices/AddInvoice"),
      },
      {
        path: "/invoice-details",
        name: "InvoiceDetails",
        component: () => import("../views/invoices/InvoiceDetails"),
      },
      // ======================================================================   Hardware
      {
        path: "/hardware",
        name: "Hardware",
        component: () => import("../views/hardware/TerminalsDashboard"),
      },
      {
        path: "/terminals",
        name: "Terminals",
        component: () => import("../views/hardware/Terminals"),
      },
      {
        path: "/terminal-details",
        name: "TerminalDetails",
        component: () => import("../views/hardware/TerminalDetails"),
      },
      {
        path: "/add-terminal",
        name: "AddTerminal",
        component: () => import("../views/hardware/AddTerminal"),
      },
      {
        path: "/terminal-request",
        name: "TerminalRequest",
        component: () => import("../views/hardware/TerminalRequest"),
      },
      {
        path: "/terminal-request/android",
        name: "TerminalRequestAndroid",
        component: () => import("../views/hardware/TerminalRequestAndroid"),
      },
      {
        path: "/terminal-request/mpos",
        name: "TerminalRequestMpos",
        component: () => import("../views/hardware/TerminalRequestMpos"),
      },
      {
        path: "/terminal-request/linux",
        name: "TerminalRequestLinux",
        component: () => import("../views/hardware/TerminalRequestLinux"),
      },
      // ======================================================================   Sales History
      {
        path: "/sales-history",
        name: "SalesHistory",
        component: () => import("../views/sales/SalesHistory"),
      },
      {
        path: "/sales-details/:id",
        name: "SalesDetails",
        component: () => import("../views/sales/SalesDetails"),
      },
      // ======================================================================   Orders
      {
        path: "/orders",
        name: "Orders",
        component: () => import("../views/orders/Orders"),
      },
      {
        path: "/order-details/:id",
        name: "OrderDetails",
        component: () => import("../views/orders/OrderDetails"),
      },
      {
        path: "/add-payment/:id",
        name: "AddPayment",
        component: () => import("../views/orders/AddPayment"),
      },
      // ======================================================================   Stores
      {
        path: "/all-stores",
        name: "AllStores",
        component: () => import("../views/stores/Stores"),
      },
      {
        path: "/add-store",
        name: "AddStore",
        component: () => import("../views/stores/AddStore"),
      },
      // ======================================================================   Inventory Control
      {
        path: "/stock-count",
        name: "StockCount",
        component: () =>
          import("../views/inventory-control/stock-count/StockCount"),
      },
      {
        path: "/new-count",
        name: "NewCount",
        component: () =>
          import("../views/inventory-control/stock-count/NewCount"),
      },
      {
        path: "/category-count",
        name: "CategoryCount",
        component: () =>
          import("../views/inventory-control/stock-count/CategoryCount"),
      },
      {
        path: "/submit-count",
        name: "SubmitCount",
        component: () =>
          import("../views/inventory-control/stock-count/SubmitCount"),
      },
      {
        path: "/accept-count",
        name: "AcceptCount",
        component: () =>
          import("../views/inventory-control/stock-count/AcceptCount"),
      },
      {
        path: "/count-completed",
        name: "CountCompleted",
        component: () =>
          import("../views/inventory-control/stock-count/CountCompleted"),
      },
      {
        path: "/add-stock",
        name: "AddStock",
        component: () =>
          import("../views/inventory-control/stock-adjustment/AddStock"),
      },
      {
        path: "/remove-stock",
        name: "RemoveStock",
        component: () =>
          import("../views/inventory-control/stock-adjustment/RemoveStock"),
      },
      {
        path: "/stock-adjustment",
        name: "StockAdjustment",
        component: () =>
          import("../views/inventory-control/stock-adjustment/StockAdjustment"),
      },
      {
        path: "/add-stock-next",
        name: "AddStockNext",
        component: () =>
          import("../views/inventory-control/stock-adjustment/AddStockNext"),
      },
      {
        path: "/remove-stock-next",
        name: "RemoveStockNext",
        component: () =>
          import("../views/inventory-control/stock-adjustment/RemoveStockNext"),
      },
      {
        path: "/inventory-transfer",
        name: "InventoryTransfer",
        component: () =>
          import(
            "../views/inventory-control/inventory-transfer/InventoryTransfer"
          ),
      },
      {
        path: "/create-transfer",
        name: "CreateTransfer",
        component: () =>
          import(
            "../views/inventory-control/inventory-transfer/CreateTransfer"
          ),
      },
      {
        path: "/transfer-detail",
        name: "TransferDetails",
        component: () =>
          import(
            "../views/inventory-control/inventory-transfer/TransferDetails"
          ),
      },
      {
        path: "/transfer-order",
        name: "TransferOrder",
        component: () => import("../views/inventory-control/TransferOrder"),
      },
      {
        path: "/print-label",
        name: "PrintLabel",
        component: () => import("../views/inventory-control/PrintLabel"),
      },
      // ======================================================================   settings
      {
        path: "/company-info",
        name: "CompanyInfo",
        component: () => import("../views/settings/CompanyInfo"),
      },
      // {
      //   path: "/bank-accounts",
      //   name: "BankAccounts",
      //   component: () => import("../views/settings/BankAccounts"),
      // },
      {
        path: "/add-account",
        name: "AddAccount",
        component: () => import("../views/settings/AddAccount"),
      },
      {
        path: "/payment-methods",
        name: "PaymentMethod",
        component: () => import("../views/settings/PaymentMethod"),
      },
      {
        path: "/user-roles",
        name: "UserRoles",
        component: () => import("../views/settings/UserRoles"),
      },
      {
        path: "/existing-roles",
        name: "ExistingRoles",
        component: () => import("../views/settings/ExistingRoles"),
      },

      {
        path: "/manage-roles-permissions",
        name: "ManageRolesPermissions",
        component: () => import("../views/settings/ManageRolesPermissions"),
        // component: () =>
        //     import ("../views/settings/ManagePermissions"),
      },

      {
        path: "/user-settings",
        name: "UserSettings",
        component: () => import("../views/settings/Users"),
      },
      {
        path: "/employee-details-user",
        name: "EmployeeDetailsUser",
        component: () => import("../views/settings/EmployeeDetailsUser"),
      },
      {
        path: "/edit-employee-details-user",
        name: "EditEmployeeDetailsUser",
        component: () => import("../views/settings/EditEmployeeDetailsUser"),
      },
      {
        path: "/online-catalogue",
        name: "OnlineCatalogue",
        component: () => import("../views/onlinecatalogue/OnlineCatalogue"),
      },
      {
        path: "/employee-details-role",
        name: "EmployeeDetailsRole",
        component: () => import("../views/settings/EmployeeDetailsRole"),
      },
      {
        path: "/fixed-account",
        name: "FixedAccount",
        component: () => import("../views/fixedAccounts/FixedAccount"),
      },
      {
        path: "/user-guides",
        name: "UserGuides",
        component: () => import("../views/UserGuides/UserGuides"),
      },
      // ======================================================================   Integrations
      {
        path: "/integrations",
        name: "Integrations",
        component: () => import("../views/settings/integrations/Integrations"),
      },
      {
        path: "/integration-connection",
        name: "IntegrationsConnection",
        component: () =>
          import("../views/settings/integrations/ConnectIntegration"),
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  routes,
});

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     if (
//       !GET_LOCAL_DB_DATA('traction-app-user-data') && !GET_LOCAL_DB_DATA('traction-app-current-user-business-data')
//     ) {
//       next({ name: 'Login' });
//     } else {
//       next();
//     }
//   } else{
//     next()
//   }

//   if (to.matched.some(record => record.meta.hideForAuth)) {
//     if (GET_LOCAL_DB_DATA('traction-app-user-data') && GET_LOCAL_DB_DATA('traction-app-current-user-business-data')) {
//         next({ path: '/dashboard' });
//     } else {
//         next();
//     }
// } else {
//     next();
// }
// });

router.beforeEach((to, from, next) => {
  if (
    to.name !== "ViewInvoiceWithoutAuth" &&
    to.name !== "ForgotPassword" &&
    to.name !== "Login" &&
    to.name !== "Signup" &&
    to.name !== "FcmbSignUp" &&
    to.name !== "FcmbLogin" &&
    !GET_LOCAL_DB_DATA("traction-app-user-data") &&
    !GET_LOCAL_DB_DATA("traction-app-current-user-business-data")
  ) {
    // Check if any logged in user exists
    if (!!Object.keys(GET_ALL_AUTH_USERS()).length) {
      const selectedUser = GET_CURRENT_AUTH_USER(
        ITERATEALL_ALL_AUTH_USERS()[0].user._id
      );

      SAVE_LOCAL_DB_DATA("traction-app-user-data", selectedUser.userData);
      SAVE_LOCAL_DB_DATA(
        "traction-app-current-user-business-data",
        selectedUser.businessData
      );

      next();
    } else {
      next({ name: "Login" });
    }
  } else {
    next();
  }
});

export default router;
