const { NODE_ENV = "" } = process.env;

const environment = NODE_ENV.toLowerCase();
const apiUrl =
  environment !== "production" || window.location.hostname.includes("staging")
  ? "https://tractionapp-stage.herokuapp.com"
  :"https://tractionapps.herokuapp.com";
const fcmbData = {
  
}
export { environment, apiUrl };