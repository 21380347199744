import { GET_USER_BUSINESS_ID } from '../../browser-db-config/localStorage';
import { HTTP_REQUEST } from '../../http-config/trac-http-req';

const actions = {
	CREATE_FIXED_ACCOUNT: async function(context, payload) {
		const httpReq = {
			url: `/v1/payments/accounts/businesses/${GET_USER_BUSINESS_ID()}/customfixedaccount`,
			method: 'POST',
			authRequest: true,
			body: payload,
		};

		const httpRes = await HTTP_REQUEST(httpReq);
		return httpRes;
	},
	GET_FIXED_ACCOUNT: async function() {
		const httpReq = {
			url: `/v1/payments/fixedaccounts/businesses/${GET_USER_BUSINESS_ID()}`,
			method: 'GET',
			authRequest: true,
		};
		const httpRes = await HTTP_REQUEST(httpReq);
		return httpRes;
	},
};

export default {
	actions,
};
