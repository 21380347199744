import { ADD_DATA_TO_DB, CONNECT_TO_DB, GET_DB_DATA, GET_OBJECT_STORE, SAVE_OBJECT_STORE } from '../../../browser-db-config/indexedDB';
import { GET_USER_BUSINESS_ID } from '../../../browser-db-config/localStorage';
import { HTTP_REQUEST } from '../../../http-config/trac-http-req';

const state = {
	allBankAccounts: null,
	availableBanks: null,
	addAccountRes: null,
};

const getters = {
	GET_ALL_BANK_ACCOUNTS: (state) => state.allBankAccounts,
	GET_ALL_BANKS: (state) => state.availableBanks,
	GET_ADD_BANK_RES: (state) => state.addAccountRes,
};

const actions = {
	FETCH_ALL_BANK_ACCOUNTS: async function (context) {
		// const offlineBankAccountsDB = await CONNECT_TO_DB('offline_db_bankAccounts', 1, 'bankAccounts');
		let offlineBankAccounts = await GET_OBJECT_STORE('offline_db_bankAccounts', 'bankAccounts');

		const httpReq = {
			url: `/v1/payments/accounts/businesses/${GET_USER_BUSINESS_ID()}`,
			method: 'GET',
			authRequest: true,
		};

		HTTP_REQUEST(httpReq).then(httpRes => {
			if (httpRes.status) {
				offlineBankAccounts = httpRes;

				if (!offlineBankAccounts.data) {
					offlineBankAccounts.data = [];
				}

				// Save assets to indexDB
				SAVE_OBJECT_STORE("offline_db_bankAccounts", 'bankAccounts', offlineBankAccounts);
				context.commit('POPULATE_ALL_BANK_ACCOUNTS', offlineBankAccounts);
			}
		});


		if (!offlineBankAccounts) {
			offlineBankAccounts = await HTTP_REQUEST(httpReq);
		}

		context.commit('POPULATE_ALL_BANK_ACCOUNTS', offlineBankAccounts);
		return offlineBankAccounts;
	},
	FETCH_ALL_BANKS: async function (context) {
		const httpReq = {
			url: 'https://api.paystack.co/bank',
			method: 'GET',
			authRequest: false,
		};

		const httpRes = await HTTP_REQUEST(httpReq);
		context.commit('POPULATE_ALL_BANKS', httpRes);
		return httpRes;
	},
	ADD_NEW_ACCOUNT: async function (context, payload) {
		// const offlineBankAccountsDB = await CONNECT_TO_DB('offline_db_bankAccounts', 1, 'bankAccounts');
		let offlineBankAccounts = await GET_OBJECT_STORE('offline_db_bankAccounts', 'bankAccounts');

		const httpReq = {
			url: `/v1/payments/accounts/businesses/${GET_USER_BUSINESS_ID()}`,
			method: 'POST',
			authRequest: true,
			body: payload,
		};

		const httpRes = await HTTP_REQUEST(httpReq);

		if (httpRes.status) {
			if (!offlineBankAccounts.data) {
				offlineBankAccounts.data = [];
			}

			offlineBankAccounts.data.push(httpRes.data);

			// Save assets to indexDB
			await SAVE_OBJECT_STORE('offline_db_bankAccounts', 'bankAccounts', offlineBankAccounts);
		}

		context.commit('POPULATE_ADDED_RES', httpRes);
	},
	EDIT_BANK_ACCOUNT: async function (context, payload) {
		// const offlineBankAccountsDB = await CONNECT_TO_DB('offline_db_bankAccounts', 1, 'bankAccounts');
		let offlineBankAccounts = await GET_OBJECT_STORE('offline_db_bankAccounts', 'bankAccounts');

		const httpReq = {
			url: `/v1/payments/accounts/businesses/${GET_USER_BUSINESS_ID()}`,
			method: 'PATCH',
			authRequest: true,
			body: payload,
		};

		const httpRes = await HTTP_REQUEST(httpReq);

		if (httpRes.status) {
			if (!offlineBankAccounts.data) {
				offlineBankAccounts.data = [];
			}

			offlineBankAccounts.data[0] = httpRes.data;

			// Save assets to indexDB
			await SAVE_OBJECT_STORE('offline_db_bankAccounts', 'bankAccounts', offlineBankAccounts);
		}

		context.commit('POPULATE_ADDED_RES', httpRes);
	},
	RESOLVE_BANK_ACCOUNT: async function (context, payload) {
		// const offlineBankAccountsDB = await CONNECT_TO_DB('offline_db_bankAccounts', 1, 'bankAccounts');
		let offlineBankAccounts = await GET_OBJECT_STORE('offline_db_bankAccounts', 'bankAccounts');

		const httpReq = {
			url: `/v1/payments/resolve/bankaccount?accountnumber=${payload.accountnumber}&bankcode=${payload.bankcode}`,
			method: 'GET',
			authRequest: true,
		};

		const httpRes = await HTTP_REQUEST(httpReq);

		if (httpRes.status) {
			offlineBankAccounts.data = httpRes.data;
		}

		context.commit('POPULATE_ADDED_RES', httpRes);
	},
	RESOLVE_BVN: async function (context, payload) {
		const httpReq = {
			url: `/v1/payments/resolve/bvn?bvn=${payload.bvn}`,
			method: 'GET',
			authRequest: true,
		};

		const httpRes = await HTTP_REQUEST(httpReq);

		context.commit('POPULATE_ADDED_RES', httpRes);
	},
};

const mutations = {
	POPULATE_ALL_BANK_ACCOUNTS: (state, data) => {
		state.allBankAccounts = data;
	},
	POPULATE_ALL_BANKS: (state, data) => {
		state.availableBanks = data;
	},
	POPULATE_ADDED_RES: (state, data) => {
		state.addAccountRes = data;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
