import { ADD_DATA_TO_DB, CONNECT_TO_DB, GET_DB_DATA, GET_OBJECT_STORE, SAVE_OBJECT_STORE } from '../../../browser-db-config/indexedDB';
import { GET_USER_BUSINESS_ID } from '../../../browser-db-config/localStorage';
import { HTTP_REQUEST } from '../../../http-config/trac-http-req';


const actions = {
	ADD_NEW_CUSTOMER_PAYMENT_OPTION: async function (context, payload) {
		const httpReq = {
			url: `/v1/payments/customoptions/businesses/${GET_USER_BUSINESS_ID()}`,
			method: 'POST',
			authRequest: true,
			body: payload,
		};
		const httpRes = await HTTP_REQUEST(httpReq);

		// const offlineCustomPaymentsDB = await CONNECT_TO_DB('offline_db_custom_payments', 1, 'custom_payments');
		let offlineCustomPayments = await GET_OBJECT_STORE('offline_db_custom_payments', 'custom_payments');

		if (offlineCustomPayments && httpRes.status) {
			((offlineCustomPayments.data || {}).items || []).push(httpRes.data);

			await SAVE_OBJECT_STORE('offline_db_custom_payments', 'custom_payments', offlineCustomPayments);
		}

		return httpRes
	},
	CUSTOMER_PAYMENT_REQUEST: async function (context, payload) {
		// fetch from db
		// const offlineCustomPaymentsDB = await CONNECT_TO_DB('offline_db_custom_payments', 1, 'custom_payments');
		let offlineCustomPayments = await GET_OBJECT_STORE('offline_db_custom_payments', 'custom_payments');

		const httpReq = {
			url: `/v1/payments/customoptions/businesses/${GET_USER_BUSINESS_ID()}`,
			method: 'GET',
			authRequest: true,
		};

		HTTP_REQUEST(httpReq).then(httpRes => {
			if (httpRes.data) {
				httpRes.data.items = httpRes.data.items || [];
				SAVE_OBJECT_STORE('offline_db_custom_payments', 'custom_payments', httpRes);
			}
		});

		if (!offlineCustomPayments) {
			offlineCustomPayments = await HTTP_REQUEST(httpReq);
		}

		return offlineCustomPayments
	},
	DELETE_CUSTOM_PAYMENT: async function (context, payload) {
		const httpReq = {
			url: `/v1/payments/customoptions/businesses/${GET_USER_BUSINESS_ID()}/paymentoption/${payload.id}`,
			method: 'DELETE',
			authRequest: true,
		};
		const httpRes = await HTTP_REQUEST(httpReq);

		if (httpRes.status) {
			// const offlineCustomPaymentsDB = await CONNECT_TO_DB('offline_db_custom_payments', 1, 'custom_payments');
			let offlineCustomPayments = await GET_OBJECT_STORE('offline_db_custom_payments', 'custom_payments');

			if (offlineCustomPayments && httpRes.status) {
				offlineCustomPayments.data.items = offlineCustomPayments.data.items.filter(item => item.id !== payload.id);

				await SAVE_OBJECT_STORE('offline_db_custom_payments', 'custom_payments', offlineCustomPayments);
			}
		}

		return httpRes
	},
};


export default {
	actions,
};
