<template>
  <!-- <div class="">
    <label class="checkbox" :class="{ 'checkbox--disabled': disableCheckbox }">
      <span class="checkbox__input">
        <input
          :checked="isChecked"
          type="checkbox"
          name="checkbox"
          :disabled="disableCheckbox"
        />
        <span class="checkbox__control">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            aria-hidden="true"
            focusable="false"
          >
            <path
              fill="none"
              stroke="currentColor"
              stroke-width="4"
              d="M1.73 12.91l6.37 6.37L22.79 4.59"
            />
          </svg>
        </span>
      </span>
      <span class="rad-text">{{ radioLabel }}</span>
    </label>
  </div> -->
  <label class="radio">
    <div class="flex items-center">
      <input
        type="radio"
        :value="selectedVal"
        :name="name"
        v-model="radioButtonValue"
      />
      <span class="px-2 cursor-pointer text-xs">
        <slot></slot>
      </span>
    </div>
  </label>
</template>

<script>
export default {
  props: ["name", "value", "selectedVal"],
  computed: {
    radioButtonValue: {
      get: function () {
        return this.value;
      },
      set: function () {
        // Communicate the change to parent component so that selectedValue can be updated
        this.$emit("change", this.value);
      },
    },
  },
  // props: {
  //   disableCheckbox: {
  //     type: Boolean,
  //     default: false,
  //   },
  //   isChecked: {
  //     type: Boolean,
  //     default: false,
  //   },
  //   radioLabel: {
  //     type: String,
  //     default: '',
  //   },
  // },
};
</script>

<style lang="scss" scoped>
.checkbox {
  font-size: 0.5rem;
  color: #0143aa;
  display: inline-flex;
  align-items: center;

  .rad-text {
    font-size: 14px;
    padding: 0 10px;
    cursor: pointer;
    color: #909090;
  }
}

.checkbox__input {
  display: inline-grid;

  > * {
    grid-area: checkbox;
  }

  input {
    opacity: 0;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
  }
}

.checkbox__control {
  cursor: pointer;
  display: inline-grid;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 10px;
  border: 2px solid #0143aa;
}

.checkbox__control svg {
  transition: all 0.1s;
  opacity: 0;
  border: 2px solid #e5e5e5;
  border-radius: 10px;
}

.checkbox__input input:checked + .checkbox__control svg {
  opacity: 1;
  background-color: #253b95;
  border: 2px solid #0143aa;
  background-color: #0143aa;
}

.checkbox__input input:checkbox:disabled + .checkbox__control svg {
  color: #959495;
  border: 1px solid #959495 !important;
  opacity: 1;
}

.checkbox--disabled {
  color: #959495;

  .checkbox__control {
    cursor: not-allowed;
    border: 1px solid #959495 !important;
  }

  .rad-text {
    cursor: not-allowed;
  }
}
</style>
