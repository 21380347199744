import { GET_USER_BUSINESS_ID } from "../../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../../http-config/trac-http-req";

const state = {
  allStockLogs: null,
  bulkUpdatesRes: null,
};

const getters = {
  GET_ALL_STOCK_LOGS: state => state.allStockLogs,
  GET_BULK_UPDATE_RES: state => state.bulkUpdatesRes,
};

const actions = {
  FETCH_STOCK_ADJUSTMENT_LOG: async function(context, payload) {
    const httpReq = {
      url: `/v1/businesses/${GET_USER_BUSINESS_ID()}/stocks/logs/`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    context.commit('SET_UP_ALL_STOCK_LOGS', httpRes);
  },
  BULK_UPDATES: async function(context, payload) {
    const httpReq = {
      url: `/v1/businesses/${GET_USER_BUSINESS_ID()}/products/bulk2/`,
      method: "PATCH",
      authRequest: true,
      body: payload,
    };
    
    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit('SET_UP_BULK_UPDATE_RES', httpRes);
  },
};

const mutations = {
  SET_UP_ALL_STOCK_LOGS: (state, data) => {
    state.allStockLogs = data;
  },
  SET_UP_BULK_UPDATE_RES: (state, data) => {
    state.bulkUpdatesRes = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
