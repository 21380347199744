import { ADD_DATA_TO_DB, CONNECT_TO_DB, GET_DB_DATA, GET_OBJECT_STORE, SAVE_OBJECT_STORE } from "../../browser-db-config/indexedDB";
import {
  GET_USER_BUSINESS_ID,
  GET_USER_DATA,
} from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {
  allStores: null,
  storeRes: null,
};

const getters = {
  GET_ALL_STORES: (state) => state.allStores,
  GET_STORE_RES: (state) => state.storeRes,
};

const actions = {
  FETCH_ALL_STORES: async function (context) {
    // fetch from db
    // const offlineStoresDB = await CONNECT_TO_DB('offline_db_stores', 1, 'offline-stores');
    // let offlineStores = await GET_DB_DATA(offlineStoresDB, 'offline-stores');
    let offlineStores = await GET_OBJECT_STORE('offline_db_stores', 'offline-stores');

    // Always fetching stores
    const httpReq = {
      url: `/v1/stores/businesses/${GET_USER_BUSINESS_ID()}`,
      method: "GET",
      authRequest: true,
    };

    // Fetch online
    HTTP_REQUEST(httpReq).then(httpRes => {
      offlineStores = httpRes;

      if (httpRes.status) {
        // Save assets to indexDB
        // await ADD_DATA_TO_DB(offlineStoresDB, 'offline-stores', offlineStores);
        SAVE_OBJECT_STORE('offline_db_stores', 'offline-stores', offlineStores);
      }

      const authUser = GET_USER_DATA();
      const userStores = authUser.stores;

      const userStoresIDs = userStores.map(store => store.id);

      const mergedStores = offlineStores.data
        ? {
          status: offlineStores.status,
          message: offlineStores.message,
          data: offlineStores.data.filter(store => userStoresIDs.includes(store._id)).map((store, i) => {
            return { ...store, ...userStores[i] };
          }),
        }
        : offlineStores;

      context.commit("SETUP_STORES", mergedStores);

    });

    if (!offlineStores) {
      offlineStores = await HTTP_REQUEST(httpReq);
    }

    if (!offlineStores) {
      offlineStores = {
        data: [],
        status: true
      };
    }

    // Offline operations
    const authUser = GET_USER_DATA();
    const userStores = authUser.stores;

    const userStoresIDs = userStores.map(store => store.id);

    const mergedStores = offlineStores.data
      ? {
        status: offlineStores.status,
        message: offlineStores.message,
        data: offlineStores.data.filter(store => userStoresIDs.includes(store._id)).map((store, i) => {
          return { ...store, ...userStores[i] };
        }),
      }
      : offlineStores;

    context.commit("SETUP_STORES", mergedStores);
    return mergedStores;
  },
  CREATE_STORE: async function (context, payload) {
    // fetch from db
    // const offlineStoresDB = await CONNECT_TO_DB('offline_db_stores', 1, 'offline-stores');
    // let offlineStores = await GET_DB_DATA(offlineStoresDB, 'offline-stores');
    let offlineStores = await GET_OBJECT_STORE('offline_db_stores', 'offline-stores');

    if (!offlineStores) {
      offlineStores = {
        data: []
      };
    }

    const httpReq = {
      url: `/v1/stores/`,
      method: "POST",
      authRequest: true,
      body: payload,
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    if (httpRes.status) {
      offlineStores.data.push(httpRes.data);
      // await ADD_DATA_TO_DB(offlineStoresDB, 'offline-stores', offlineStores);
      await SAVE_OBJECT_STORE('offline_db_stores', 'offline-stores', offlineStores);
    }

    context.commit("STORE_ACTION_RES", httpRes);
  },
  UPDATE_STORE: async function (context, payload) {
    // fetch from db
    // const offlineStoresDB = await CONNECT_TO_DB('offline_db_stores', 1, 'offline-stores');
    // let offlineStores = await GET_DB_DATA(offlineStoresDB, 'offline-stores');
    let offlineStores = await GET_OBJECT_STORE('offline_db_stores', 'offline-stores');


    const httpReq = {
      url: `/v1/stores/${payload._id}`,
      method: "PATCH",
      authRequest: true,
      body: payload,
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    if (httpRes.status) {
      offlineStores.data = offlineStores.data.map(store => {
        if (store._id === payload._id) {
          store = httpRes.data;
        }

        return store;
      });
      // await ADD_DATA_TO_DB(offlineStoresDB, 'offline-stores', offlineStores);
      await SAVE_OBJECT_STORE('offline_db_stores', 'offline-stores', offlineStores);
    }

    context.commit("STORE_ACTION_RES", httpRes);
  },
};

const mutations = {
  SETUP_STORES: (state, data) => {
    state.allStores = data;
  },
  STORE_ACTION_RES: (state, data) => {
    state.storeRes = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
