import { HTTP_REQUEST } from "../../../http-config/trac-http-req";

const state = {};

const getters = {};

const actions = {};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
}