<template>
  <div class="mt-2 flex flex-row items-center">
    <img src="./../../assets/svg/error.svg" />
    <span class="font-light text-xs text-secondaryRed ml-2"
      >
      <slot></slot>
      </span
    >
  </div>
</template>

<script>
export default {};
</script>

<style></style>
