import { GET_USER_BUSINESS_ID } from '../../browser-db-config/localStorage';
import { HTTP_REQUEST } from '../../http-config/trac-http-req';

const state = {
	orderLog: null,
};

const getters = {};

const actions = {
	FETCH_ALL_ORDERS: async function(context, payload) {
		// fetch from db
		// fetch from db (not-synced)

		// Fetch from server
		const httpReq = {
			url: `/v1/orders/businesses/${GET_USER_BUSINESS_ID()}`,
			method: 'GET',
			authRequest: true,
		};

		const httpRes = await HTTP_REQUEST(httpReq);
		return httpRes;
	},
	FETCH_ORDER: async function(context, payload) {
		// Fetch from db

		// If order found no need to fetch it from server
		const httpReq = {
			url: `/v1/orders/businesses/${GET_USER_BUSINESS_ID()}/${payload.id}`,
			method: 'GET',
			authRequest: true,
		};

		const httpRes = await HTTP_REQUEST(httpReq);
		return httpRes;
	},
	TOGGLE_ONLINE_STORE: async function(context, payload) {
		// fetch from db
		// fetch from db (not-synced)

		// Fetch from server
		const httpReq = {
			url: `/v1/stores/onlinestore/${payload.business}/toggle`,
			method: 'POST',
			authRequest: true,
			body: payload,
		};

		const httpRes = await HTTP_REQUEST(httpReq);
		return httpRes;
	},
	REQUEST_PAYMENT: async function(context, payload) {
		const httpReq = {
			url: `/v1/orders/businesses/${GET_USER_BUSINESS_ID()}/${
				payload.orderId
			}/payment/${payload.paymentMethod}`,
			method: 'GET',
			authRequest: true,
		};

		const httpRes = await HTTP_REQUEST(httpReq);
		return httpRes;
	},
	ADD_FEE: async function(context, payload) {
		const httpReq = {
			url: `/v1/orders/addfee/`,
			method: 'PATCH',
			authRequest: true,
			body: payload.body,
		};

		const httpRes = await HTTP_REQUEST(httpReq);
		return httpRes;
	},
	RECORD_PAYMENT: async function(context, payload) {
		const httpReq = {
			url: `/v1/orders/recordpayment/`,
			method: 'PATCH',
			authRequest: true,
			body: payload,
		};

		const httpRes = await HTTP_REQUEST(httpReq);
		return httpRes;
	},
	UPDATE_ORDER_STATUS: async function(context, payload) {
		const httpReq = {
			url: `/v1/orders/update/`,
			method: 'PATCH',
			authRequest: true,
			body: payload.body,
		};

		const httpRes = await HTTP_REQUEST(httpReq);
		return httpRes;
	},
};

const mutations = {
	logOrders: (state, data) => {
		state.orderLog = data;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
