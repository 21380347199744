<template>
  <div class="flex justify-center items-center w-full h-full bg-transparent">
    <slot></slot>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>