<template>
  <div class="w-full h-full">
    <img
      :src="imgURL"
      v-if="imgURL !== '' && imgURL"
      class="w-auto block m-auto"
      :class="size"
    />
    <h1 v-else class="bg-gray-500 text-5xl text-white text-center uppercase flex justify-center items-center w-4/5 m-auto" :class="size">
      {{ name.substring(0, 1) }}
    </h1>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
    },
    imgURL: {
      type: String,
    },
    size: {
      type: String,
      default: 'h-full'
    },
  }
};
</script>

<style>
</style>
