import { openDB, deleteDB } from 'idb';
import { GET_LOCAL_DB_DATA, GET_USER_BUSINESS_DATA, GET_USER_BUSINESS_ID } from './localStorage';

async function CONNECT_TO_DB(dbName, version, objectStoreName) {
  const db = await openDB(dbName, version, {
    upgrade(db) {
      db.createObjectStore(objectStoreName);
    }
  });

  return db;
}

async function ADD_DATA_TO_DB(db, objectStoreName, res) {
  await DELETE_DATA_IN_DB(db, objectStoreName);
  await db.add(objectStoreName, res, objectStoreName);
}

async function DELETE_DATA_IN_DB(db, objectStoreName) {
  await db.delete(objectStoreName, objectStoreName);
}

async function CLEAR_KEY_IN_DB(dbName, objectStoreName) {
  // CONNECT_TO_DB(dbName, 1, objectStoreName)
  //   .then(db => {
  //     DELETE_DATA_IN_DB(db, objectStoreName);
  //     // db.add(objectStoreName, null, objectStoreName);
  //   }).catch(error => {
  //     console.log(error);
  //   });
  // deleteDB(dbName, 1)
  //   .then(data => {
  //     console.log(data);
  //   }).catch(error => {
  //     console.log(error);
  //   });
}

function CLEAR_LIST() {
  // CLEAR_KEY_IN_DB("offline_db_all_loyalty_points", "all_loyalty_points");
  // CLEAR_KEY_IN_DB("offline_db_all_store_credits", "all_store_credits");
  // CLEAR_KEY_IN_DB("offline_db_categories", "categories");
  // CLEAR_KEY_IN_DB("offline_db_categories_with_sub", "categories_with_sub");
  // CLEAR_KEY_IN_DB("offline_db_customers", "customers");
  // CLEAR_KEY_IN_DB("offline_db_invoices", "invoices");
  // CLEAR_KEY_IN_DB("offline_db_products", "products");
  // CLEAR_KEY_IN_DB("offline_db_sales_history", "sales_history");
  // CLEAR_KEY_IN_DB("offline_db_stores", "offline-stores");
  // CLEAR_KEY_IN_DB("auth_data_db", "auth_data");
  // CLEAR_KEY_IN_DB("offline_db_employees", "employees");
  // CLEAR_KEY_IN_DB("business_data_db", "business_data");
  // CLEAR_KEY_IN_DB("offline_db_bankAccounts", "bankAccounts");
  // CLEAR_KEY_IN_DB("offline_db_custom_payments", "custom_payments");
  // CLEAR_KEY_IN_DB("secondary_token_db", "secondary_token");
}

async function CLEAR_ALL_DATA_IN_DB() {
  // const dbs = await window.indexedDB.databases();
  // dbs.forEach(db => {
  //   // if (!db.name.includes('not_synced_offline') || db.name.includes('auth_data_db')) {
  //   // }
  //   // window.indexedDB.deleteDatabase(db.name);
  // })
}

async function CLEAR_SPECIFIC_DB(dbName) {
  const dbs = await window.indexedDB.databases();
  dbs.forEach(db => {
    if (!db.name === dbName) {
      window.indexedDB.deleteDatabase(db.name);
    }
  })
}

async function GET_DB_DATA(db, objectStoreName) {
  const resData = await db.get(objectStoreName, objectStoreName);
  return resData;
}

let db;

// New state
const OPEN_INDEXED_DB = (dbName, objectStoreName) => {
  const indexedDBReq = indexedDB.open(dbName, 1);

  return new Promise((resolve, reject) => {
    /* Error callback */
    indexedDBReq.addEventListener('error', err => {
      reject(err);
    });

    /* Success callback */
    indexedDBReq.addEventListener('success', ev => {
      db = ev.target.result;
      resolve(db);
    });

    /* Upgradeneeded callback */
    indexedDBReq.addEventListener('upgradeneeded', ev => {
      db = ev.target.result;

      if (!db.objectStoreNames.contains(objectStoreName)) {
        // Create objectstore
        db.createObjectStore(objectStoreName);
      }
    });
  });
};

const GET_OBJECT_STORE = (dbName, objectStoreName) => {
  return new Promise((resolve, reject) => {
    OPEN_INDEXED_DB(dbName, objectStoreName).then(db => {
      // Create transaction
      const tx = db.transaction(objectStoreName, 'readonly');

      // Transaction event
      tx.oncomplete = ev => {
      }

      tx.onerror = ev => {
      }

      const txStoreObject = tx.objectStore(objectStoreName);
      // Perform operation
      const requestObject = txStoreObject.get(GET_USER_BUSINESS_ID());

      requestObject.onsuccess = ev => {
        const data = ev.target.result;
        resolve(data);
      };

      requestObject.onerror = err => {
        reject(err);
      };
    });
  });
};

const SAVE_OBJECT_STORE = (dbName, objectStoreName, payload) => {
  return new Promise((resolve, reject) => {
    OPEN_INDEXED_DB(dbName, objectStoreName).then(db => {

      // Check if object store exists
      GET_OBJECT_STORE(dbName, objectStoreName).then(data => {
        // Create transaction
        const tx = db.transaction(objectStoreName, 'readwrite');

        // Transaction event
        tx.oncomplete = ev => {
        }

        tx.onerror = ev => {
        }

        const txStoreObject = tx.objectStore(objectStoreName);

        // Perform operation
        let requestObject;

        if (data) {
          requestObject = txStoreObject.put(payload, GET_USER_BUSINESS_ID());
        } else {
          requestObject = txStoreObject.add(payload, GET_USER_BUSINESS_ID());
        }

        requestObject.onsuccess = ev => {
          resolve(ev);
        };

        requestObject.onerror = err => {
          reject(err);
        };
      });
    });
  });
};

const DELETE_OBJECT_STORE_KEY = () => {

};

const SAVE_CURRENT_BUSINESS_TO_OBJECT_STORE = (dbName = 'current-active-business-db', objectStoreName = 'current-active-business') => {
  return new Promise((resolve, reject) => {
    OPEN_INDEXED_DB(dbName, objectStoreName).then(db => {

      // Check if object store exists
      GET_OBJECT_STORE(dbName, objectStoreName).then(data => {
        // Create transaction
        const tx = db.transaction(objectStoreName, 'readwrite');

        // Transaction event
        tx.oncomplete = ev => {
        }

        tx.onerror = ev => {
        }

        const txStoreObject = tx.objectStore(objectStoreName);

        // Perform operation
        let requestObject;

        if (data) {
          requestObject = txStoreObject.put(GET_USER_BUSINESS_DATA(), 'current-active-business');
        } else {
          requestObject = txStoreObject.add(GET_USER_BUSINESS_DATA(), 'current-active-business');
        }

        requestObject.onsuccess = ev => {
          resolve(ev);
        };

        requestObject.onerror = err => {
          reject(err);
        };
      });
    });
  });
};

export { CLEAR_SPECIFIC_DB, DELETE_OBJECT_STORE_KEY, CONNECT_TO_DB, GET_DB_DATA, ADD_DATA_TO_DB, DELETE_DATA_IN_DB, CLEAR_ALL_DATA_IN_DB, CLEAR_LIST, CLEAR_KEY_IN_DB, SAVE_OBJECT_STORE, GET_OBJECT_STORE, SAVE_CURRENT_BUSINESS_TO_OBJECT_STORE };
