import { GET_USER_BUSINESS_ID } from '../../browser-db-config/localStorage';
import { HTTP_REQUEST } from '../../http-config/trac-http-req';

const actions = {
	GET_PRODUCTS_DATA: async function(context, payload) {
		const httpReq = {
			url: `/v1/dashboard/sales/revenue/${GET_USER_BUSINESS_ID()}/${
				payload.startDate
			}/${payload.endDate}/${payload.id}`,
			method: 'GET',
			authRequest: true,
		};

		const httpRes = await HTTP_REQUEST(httpReq);

		return httpRes;
	},
	GET_CUSTOMER_DATA: async function(context, payload) {
		const httpReq = {
			url: `/v1/dashboard/customer/${GET_USER_BUSINESS_ID()}/${
				payload.startDate
			}/${payload.endDate}/${payload.id}`,
			method: 'GET',
			authRequest: true,
		};

		const httpRes = await HTTP_REQUEST(httpReq);
		return httpRes;
	},
	GET_INVENTORY_DATA: async function(context, payload) {
		const httpReq = {
			url: `v1/dashboard/inventory/revenue/${GET_USER_BUSINESS_ID()}/${
				payload.startDate
			}/${payload.endDate}/${payload.id}`,
			method: 'GET',
			authRequest: true,
		};

		const httpRes = await HTTP_REQUEST(httpReq);
		return httpRes;
	},
	// GET_PAYMENT_LOG_DETAILS: async function(context, payload) {
	// 	const httpReq = {
	// 		url: `/v1/payments/paymentlogdetails/businesses/${GET_USER_BUSINESS_ID()}/paymentlogid/${payload}`,
	// 		method: 'GET',
	// 		authRequest: true,
	// 	};

	// 	const httpRes = await HTTP_REQUEST(httpReq);
	// 	return httpRes;
	// },

	// GET_PAYOUT_DETAILS: async function(context, payload) {
	// 	const httpReq = {
	// 		url: `/v1/payments/payoutdetails/businesses/${GET_USER_BUSINESS_ID()}/payoutid/${payload}`,
	// 		method: 'GET',
	// 		authRequest: true,
	// 	};

	// 	const httpRes = await HTTP_REQUEST(httpReq);
	// 	return httpRes;
	// },
};
const mutations = {};

export default {
	actions,
	mutations,
};
