<template>
  <div id="app">
    <!-- <button @click="clearStuff" class="block m-20">Clear</button> -->
    <router-view />
    <div
      v-if="updateExists"
      @click="refreshApp"
      class="fixed bottom-0 cursor-pointer max-w-sm w-full right-0 inline-block m-10 px-6 py-4 shadow-md btn-gradient rounded-md z-40"
    >
      <div class="flex items-center justify-start mb-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          class="stroke-current text-white"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#909090"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <circle cx="12" cy="12" r="10"></circle>
          <line x1="12" y1="8" x2="12" y2="12"></line>
          <line x1="12" y1="16" x2="12.01" y2="16"></line>
        </svg>
        <p class="text-xs font-semibold text-white ml-2">
          New version available!
        </p>
      </div>
      <div class="flex justify-end">
        <button>
          <span class="text-xs text-white">Click to update</span>
        </button>
      </div>
    </div>

      <!-- class="z-40" -->
    <!-- style="z-index: 99999;" -->
    <trac-confirmation
      @close="alertDetails.showNotification = $event"
      :type="true"
      :active="alertDetails.showNotification"
    >
      {{ alertDetails.alertMessage }}
    </trac-confirmation>
  </div>
</template>

<script>
import {
  CLEAR_KEY_IN_DB,
  CLEAR_LIST,
  SAVE_CURRENT_BUSINESS_TO_OBJECT_STORE,
} from "./browser-db-config/indexedDB";
import { GET_USER_BUSINESS_DATA, GET_USER_BUSINESS_ID } from "./browser-db-config/localStorage";
import { eventBus } from "./main";

export default {
  data() {
    return {
      refreshing: false,
      alertDetails: {
        alertMessage: "",
        showNotification: false,
      },
      registration: null,
      updateExists: false,
    };
  },
  created() {
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    document.addEventListener("swRegistered", this.sendBusinessDataToSW, {
      once: true,
    });

    // SAVE_CURRENT_BUSINESS_TO_OBJECT_STORE().then(data => {
    //   console.log(data);
    // }).catch(err => console.log(err));

    this.refreshModules();

    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.refreshing) {
          return;
        }
        this.refreshing = true;
        window.location.reload();
      });
    }

    // EventBus for notification
    eventBus.$on("trac-alert", (data) => {
      // Show notification
      this.alertDetails.alertMessage = data.message;
      this.alertDetails.showNotification = true;
    });
  },
  methods: {
    // clearStuff() {
    //   CLEAR_LIST();
    // },
    sendBusinessDataToSW(e) {
      navigator.serviceWorker.controller.postMessage({
        business: GET_USER_BUSINESS_ID(),
      });
      // this.registration.postMessage({business: GET_USER_BUSINESS_DATA()});
    },
    refreshApp() {
      // Clear all data on localStorage
      // localStorage.clear();

      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage("skipWaiting");
    },
    showRefreshUI(e) {
      this.registration = e.detail;
      this.updateExists = true;
    },
    refreshModules() {
      const interval = 60 * 30;

      setInterval(() => {
        if (GET_USER_BUSINESS_DATA()) {
          // this.$store.dispatch("REFRESH_ALL_CUSTOMERS");
          // this.$store.dispatch("REFRESH_ALL_PRODUCTS");
          // this.$store.dispatch("REFRESH_SALES_HISTORY");
          // Take this out 1st of june........
          // this.$store.dispatch('AUTO_LOGIN');
        }
      }, interval * 1000);
    },
  },
};
</script>

<style lang="scss">
* {
  font-family: "Montserrat", sans-serif !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.btn-gradient {
  background: linear-gradient(318.39deg, #0143aa 8.55%, #003283 90.68%);
}
.btn-gradient:focus {
  background: inherit;
}
.white-gradient {
  box-shadow: 0px 0px 12.9699px rgba(22, 120, 176, 0.12);
}
.smallest {
  font-size: 0.6rem !important;
}
.tiny {
  font-size: 0.55rem !important;
}
.very-tiny {
  font-size: 0.5rem;
}
.small-shadow {
  box-shadow: 0px 0px 11.4791px rgba(22, 120, 176, 0.12);
}
.big-shadow {
  box-shadow: 0px 0px 20px rgba(22, 120, 176, 0.12);
}
.auth-shadow {
  box-shadow: 0px 4px 20px rgba(22, 120, 176, 0.12);
}
input:-webkit-autofill {
  /* box-shadow: 0 0 0px 1000px #1b1b1b inset; */
  box-shadow: 0 0 0px 1000px white inset !important;
  -webkit-text-fill-color: black;
  font-family: "Montserrat", sans-serif !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// Auto scroll
#app {
  overflow-anchor: none;
}
.VuePagination__pagination {
  display: flex;
}
.VuePagination__pagination-item {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 20px rgba(22, 120, 176, 0.12);
  border-radius: 5px;
  margin: 0 3px;
  cursor: pointer;
  border: 1px solid #555454;
  color: #555454;
}
.VuePagination__pagination .active {
  color: #253b95;
  border: 1px solid #253b95;
}
.VuePagination__pagination a.active {
  border: none;
}
.VuePagination__pagination .disabled {
  color: #c0c0c0;
  cursor: not-allowed;
  border: 1px solid #c0c0c0;
}
.VuePagination__count {
  display: none;
}
.spinner {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  z-index: 2;
  width: 40px;
  height: 40px;
}
.spinner .path {
  stroke: #93bfec;
  stroke-linecap: round;
  -webkit-animation: dash 1.5s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite;
}

@-webkit-keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #fcf9f9;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgb(177, 177, 177);
}
.input-focus:focus-visible {
  outline: 2px solid #253b95 !important;
}
</style>
