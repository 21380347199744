import { GET_USER_BUSINESS_ID } from "../../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../../http-config/trac-http-req";

const state = {
  allStockCounts: null,
  stockCountRes: null,
};

const getters = {
  GET_ALL_STOCK_COUNTS: state => state.allStockCounts,
  GET_STOCK_COUNTS_RES: state => state.stockCountRes,
};

const actions = {
  FETCH_ALL_STOCK_COUNT: async function(context, payload) {
    const httpReq = {
      url: `/v1/businesses/${GET_USER_BUSINESS_ID()}/stocks/counts/`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    context.commit('SETUP_ALL_STOCK', httpRes);
  },
  FETCH_STOCK_COUNT_DETAIL: async function(context, payload) {
    const httpReq = {
      url: `/v1/businesses/${GET_USER_BUSINESS_ID()}/stocks/counts/${
        payload._id
      }/`,
      method: "GET",
      authRequest: true,
    };
    
    const httpRes = await HTTP_REQUEST(httpReq);
    // context.commit('SETUP_STOCK_COUNT_RES', httpRes);
  },
  CREATE_STOCK_COUNT: async function(context, payload) {
    const httpReq = {
      url: `/v1/businesses/${GET_USER_BUSINESS_ID()}/stocks/counts/`,
      method: "POST",
      authRequest: true,
      body: payload,
    };
    
    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit('SETUP_STOCK_COUNT_RES', httpRes);
  },
  ACCEPT_COUNT: async function(context, payload) {
    const httpReq = {
      url: `/v1/businesses/${GET_USER_BUSINESS_ID()}/products/bulk/`,
      method: "PATCH",
      authRequest: true,
      body: payload,
    };
    
    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit('SETUP_STOCK_COUNT_RES', httpRes);
  },
};

const mutations = {
  SETUP_ALL_STOCK: (state, data) => {
    state.allStockCounts = data;
  },
  SETUP_STOCK_COUNT_RES: (state, data) => {
    state.stockCountRes = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
