<template>
  <main :class="active ? 'overflow-x-hidden' : ''">
    <trac-topnav class="z-10" @toggle="toggle" />
    <div class="flex">
      <Sidebar
        @toggle="active = !active"
        :class="!active ? 'w-24' : 'w-65'"
        class="
          h-screen
          overflow-y-auto
          mt-16
          pb-24
          bg-white
          shadow-md
          fixed
          transition-all
          duration-500
          ease-in-out
          hidden
          md:block
        "
      />
        <!-- @toggle="toggle" -->
      <Sidebar
        :class="!sideBarActiveState ? 'w-0 hidden' : 'w-65'"
        class="
          h-screen
          overflow-y-auto
          mt-16
          pb-24
          bg-white
          shadow-md
          fixed
          transition-all
          duration-500
          ease-in-out
          md:hidden
        "
      />
      <div
        :class="!active ? 'pl-4 md:pl-24 print:pl-0' : 'pl-65 flex-grow'"
        class="
          pt-16
          flex-1
          transition-all
          duration-500
          ease-in-out
          print-width
          w-screen
        "
      >
        <div class="md:pl-5 md:pr-8 md:pt-6 md:pb-8 print-width">
          <trac-center-data
            v-if="appError"
            class="h-screen w-screen fixed top-0 left-0"
          >
            <span class="px-4 text-xs">{{ message }} </span>
            <button
              class="text-primaryBlue text-xs cursor-pointer font-bold"
              @click="reloadPage"
            >
              Click to Roload
            </button>
          </trac-center-data>
          <router-view v-else></router-view>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Sidebar from "@/components/components/Sidebar";
import { eventBus } from "./../main";

export default {
  components: {
    Sidebar,
  },
  data() {
    return {
      active: false,
      appError: false,
      message: null,
      errorType: null,
      reloadFunction: null,
    };
  },
  computed: {
    sideBarActiveState() {
      return this.active;
    },
  },
  created() {
    // EventBus for notification
    eventBus.$on("trac-error-alert", (payload) => {
      // this.appError = true;
      // this.errorType = payload.errorType;
      // this.message = payload.message;
      // this.reloadFunction = payload.reloadFunction;
    });
  },
 watch: {
    $route(x) {
      if (x) {
        if (screen.width < 640 && this.active) {
          this.active = !this.active;
        }
      }
    },
  },
  methods: {
    reloadPage() {
      window.location.reload();
    },
    toggle(event) {
      this.active = event.active;
    },
  },
};
</script>

<style scoped>
@media print {
  .print-width {
    /* padding: 0;
 margin: 0;
 width: 100%; */
  }
  .non-print-slip {
    display: none;
  }
}
</style>